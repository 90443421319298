import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { get } from "@/redux/module/api";
import { isAdminUser } from "@/Utils/Utils";

export default function PostMenu({
  postId,
  isMe,
}: {
  postId: number;
  isMe: number;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    const checkAdmin = async () => {
      setIsAdmin(await isAdminUser());
    };
    checkAdmin();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        <IconButton
          id="basic-button"
          onClick={handleClick}
          style={{
            zIndex: 0.5,
            position: "absolute",
            right: 0,
            padding: "10px",
            marginRight: "15px",
          }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MoreVertIcon
            id="menu"
            style={{
              width: "17px",
              height: "17px",
            }}
          />
        </IconButton>
      </>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isMe === 1 && isAdmin && (
          <MenuItem
            onClick={async () => {
              try {
                const res = await get("post/delete/" + postId, {});
                if (res.status === 200) {
                  if (window.confirm("삭제하시겠습니까?")) {
                    //   alert("삭제되었습니다.");
                    window.location.reload();
                  }
                }
              } catch (e: any) {
                console.log(e);

                if (e.response.status === 401) {
                  alert("로그인이 필요합니다.");
                } else {
                  alert("삭제에 실패했습니다.");
                }
              }
            }}
          >
            삭제
          </MenuItem>
        )}
        {/* <MenuItem onClick={handleClose}>숨기기</MenuItem> */}
        {isMe === 0 && <MenuItem onClick={handleClose}>신고</MenuItem>}
      </Menu>
    </>
  );
}
