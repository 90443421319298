import React, { Component } from "react";

import { PropsFromRedux, connector } from "@/redux/module/product/ProductProps";
import { ProductDetailImg, ProductListPropsType } from "@/types/ProductTypes";
import ProductListNode from "./ProductListNode";
import { ListPropsType } from "@/types/PropsTypes";
import ProductDetailDrawer from "./ProductDetailDrawer";
import {
  addRecentlyViewedProduct,
  getCurrentDate,
  getDetailImgList,
  isLoginUser,
  restoreScrollPosition,
  restoreScrollPositionPage,
  saveScrollPosition,
} from "@/Utils/Utils";
import Constants from "@/Utils/Constants";
import { getDateDifference } from "./../../Utils/Utils";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { orangePrimary } from "@/styles/theme";
import { LoadingState } from "@/redux/module/createReducers";
import { get } from "@/redux/module/api";
import { withRouter, NextRouter } from "next/router";

interface WithRouterProps {
  router: NextRouter;
}

interface MyComponentProps extends WithRouterProps {}

class ProductList extends Component<
  ListPropsType & {
    type: string;
    isAutoLoad?: boolean;
    listSize?: number;

    animeId1?: number;
    animeId2?: number;
    animeId3?: number;
    animeId4?: number;
    animeId5?: number;

    onPromotionListSet?: (promotionList: any) => void;
  } & MyComponentProps,
  {
    resData: ProductListPropsType[];
    drawerOpen: boolean;
    selectedProductId: number;
    isLogin: boolean;
    isLoading: boolean;
    pageNumber: number;
  }
> {
  router = this.props.router;
  PAGE_SIZE = 30;
  observer: IntersectionObserver | null = null;

  loadingElementRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      resData: [],
      isLoading: true,

      drawerOpen: false,
      selectedProductId: 0,
      pageNumber: 0,

      isLogin: false,
    };

    this.loadingElementRef = React.createRef<HTMLDivElement>();
  }

  handleClick = () => {};

  onCartClick = (productId: any) => {
    this.setState((prevState) => ({
      ...prevState,
      drawerOpen: true,
      selectedProductId: productId,
    }));
  };

  handleLoadingClose = () => {
    this.setState((prevState) => ({
      ...prevState,

      isLoading: false,
    }));
  };

  onCartToggleDrawer = (newOpen: boolean) => () => {
    console.log("onCartToggleDrawer");
    this.setState((prevState) => ({
      ...prevState,
      drawerOpen: newOpen,
    }));
  };

  componentDidUpdate(prevProps: any) {
    // 페이지 이동 시 현재 스크롤 위치를 저장
    // if (this.props.location !== prevProps.location) {
    //   sessionStorage.setItem(
    //     `scrollY:${this.props.location.pathname}`,
    //     String(window.scrollY)
    //   );
    // }
  }

  handleRouteChange = async (url: string) => {
    console.log("handleRouteChange");
    if (url === this.router.asPath) {
      // 뒤로 가기로 페이지로 돌아왔을 때의 로직을 여기에 추가
      console.log("back");
      let page = restoreScrollPositionPage() as number;

      if (page !== undefined && page !== null && page !== 0) {
        await this.fetchMoreProductListByPageNumber(page + 1);
      }
      restoreScrollPosition();
    }
  };

  async componentDidMount() {
    this.router.events.on("beforeHistoryChange", this.handleRouteChange);

    if (this.props.isAutoLoad === undefined || this.props.isAutoLoad === true) {
      window.addEventListener("popstate", this.handlePopstate);

      this.observer = new IntersectionObserver(this.handleIntersection, {
        root: null, // 스크롤 컨테이너를 사용하려면 해당 요소를 지정
        threshold: 1.0, // 관찰 대상 요소의 100%가 교차하면 콜백 함수 호출
      });

      // Intersection Observer 시작
      if (this.loadingElementRef.current) {
        this.observer.observe(this.loadingElementRef.current);
      }
    }

    // 페이지 렌더링 시 저장한 스크롤 위치를 복원
    // try {
    //   const savedScrollY = sessionStorage.getItem(
    //     `scrollY:${this.props.location.pathname}`
    //   );
    //   window.scrollTo(0, Number(savedScrollY) || 0);
    // } catch (e) {}

    let params: any = this.getParams(0);

    // this.props.fetchListProduct(params, 0);

    const res = await get(params.url, params);

    this.setState({
      resData: res.data.resData as ProductListPropsType[],
      pageNumber: 0,
      drawerOpen: false,
      selectedProductId: 0,
    });
    if (this.props.isWishList) {
      this.PAGE_SIZE = 20;

      isLoginUser().then((result) => {
        this.setState((prevState) => ({
          ...prevState,
          isLogin: result,
        }));
      });
    }

    if (this.props.isSearch) {
      await get("search/count/" + this.props.keyword, {});
    }
  }
  getParams = (pageNumer: number) => {
    let params = {};

    if (this.props.categoryId) {
      params = {
        url: "category/" + this.props.categoryId + "/products",
        categoryId: this.props.categoryId,
        pageNumber: pageNumer,
        pageSize: this.PAGE_SIZE,
      };
    } else if (this.props.isWishList) {
      params = {
        url: "member/wishlist",
        pageNumber: pageNumer,
        pageSize: this.PAGE_SIZE,
      };
    } else if (this.props.isSearch) {
      params = {
        url: "search/" + this.props.keyword,
        pageNumber: pageNumer,
        pageSize: this.PAGE_SIZE,
      };
    } else {
      if (
        this.props.type === Constants.PRODUCT_FETCH_LIST_TYPE.BEST_SELLER ||
        this.props.type === Constants.PRODUCT_FETCH_LIST_TYPE.NEW_ARRIVAL ||
        this.props.type === Constants.PRODUCT_FETCH_LIST_TYPE.RESERVATION ||
        this.props.type === Constants.PRODUCT_FETCH_LIST_TYPE.RECOMMEND
      ) {
        if (this.props.type === Constants.PRODUCT_FETCH_LIST_TYPE.RECOMMEND) {
          params = {
            url: "product/fetchList/" + this.props.type,
            pageNumber: pageNumer,
            pageSize: this.PAGE_SIZE,

            animeId1: this.props.animeId1,
            animeId2: this.props.animeId2,
            animeId3: this.props.animeId3,
            animeId4: this.props.animeId4,
            animeId5: this.props.animeId5,
          };
        } else {
          params = {
            url: "product/fetchList/" + this.props.type,
            pageNumber: pageNumer,
            pageSize: this.PAGE_SIZE,
          };
        }
      } else {
        params = {
          url: "product",
          pageNumber: pageNumer,
          pageSize: this.PAGE_SIZE,
        };
      }
    }

    return params;
  };

  componentWillUnmount() {
    if (this.props.isAutoLoad === undefined || this.props.isAutoLoad === true) {
      // this.router.events.off("beforeHistoryChange", this.handleRouteChange);

      // 컴포넌트가 언마운트될 때 이전 페이지의 URL을 초기화하고 이벤트 리스너를 제거
      window.removeEventListener("popstate", this.handlePopstate);
      this.observer?.disconnect();
    }
  }

  onClick = (recentProduct: ProductListPropsType) => {
    addRecentlyViewedProduct(recentProduct);
    saveScrollPosition(this.state.pageNumber);
  };

  fetchMoreProductListByPageNumber = async (targetPageNumber: number) => {
    let target = targetPageNumber;
    let i = 0;

    const prevResData = new Array<ProductListPropsType>();

    while (target >= this.state.pageNumber) {
      // const pagination = this.props.pagination;
      let params: any = this.getParams(this.state.pageNumber + i);
      i++;
      const res = await get(params.url, params);

      if (res.status === 200) {
        console.log("fetchMoreProductListByPageNumber");
        if (res.data.resData.length === 0) {
          return;
        }
        if (i == 0 && this.props.onPromotionListSet) {
          this.props.onPromotionListSet(res.data.resData);
          console.log("onPromotionListSet");
          console.log(res.data.resData);
        }

        prevResData.push(...(res.data.resData as ProductListPropsType[]));
      }
      target--;
    }

    this.setState((prevState) => ({
      ...prevState,
      resData: prevResData,
      pageNumber: targetPageNumber,
      drawerOpen: false,
      selectedProductId: 0,
    }));
  };

  async fetchMoreProductList() {
    // const pagination = this.props.pagination;
    let params: any = this.getParams(this.state.pageNumber + 1);

    const res = await get(params.url, params);
    const prevResData = this.state.resData;

    if (res.status === 200) {
      if (res.data.resData.length === 0) {
        return;
      }
      if (this.state.pageNumber == 0 && this.props.onPromotionListSet) {
        this.props.onPromotionListSet(res.data.resData);
        console.log("onPromotionListSet");
        console.log(res.data.resData);
      }

      this.setState({
        resData: prevResData.concat(res.data.resData as ProductListPropsType[]),
        pageNumber: this.state.pageNumber + 1,
        drawerOpen: false,
        selectedProductId: 0,
      });
    }
    // this.props.fetchListProduct(params, 0);
  }
  handleIntersection: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[]
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
        // 교차 여부를 확인하는 요소가 loadingElementRef일 때만 로드
        if (entry.target === this.loadingElementRef.current) {
          this.fetchMoreProductList();
        }
      }
    });
  };
  handlePopstate = () => {
    // 페이지 뒤로가기 발생 시 처리할 로직을 여기에 구현
    // 예: 추가된 상품 상태를 초기화하거나 다른 동작 수행
  };

  printTitle = (text: string) => {
    return (
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        onClick={(e) => {
          if (this.props.isAutoLoad === false) {
            location.href = "/product/list/" + this.props.type;
          }
        }}
      >
        <div className="titleDiv">
          <b>
            <span style={{ color: "#fffff" }}>{text}</span>
          </b>
        </div>
        {this.props.isAutoLoad === false && (
          <div
            onClick={(e) => {
              location.href = "/product/list/" + this.props.type;
            }}
            style={{
              textAlign: "right",
              fontSize: "0.8rem",
              padding: "8px",
            }}
          >
            <b> 더보기 ▶ </b>
          </div>
        )}
      </div>
    );
  };

  printProductList = (list: any, lineSize: number, title: any) => {
    return list ? (
      list.length > 0 ? (
        <>
          {title ? title : null}
          {list.map((data: any, idx: number, resDataList: any) =>
            ((this.props.isAutoLoad !== undefined &&
              this.props.listSize !== undefined &&
              this.props.isAutoLoad === false &&
              this.props.listSize > idx) ||
              this.props.isAutoLoad === undefined ||
              this.props.isAutoLoad === true) &&
            idx % lineSize === 0 ? (
              <>
                {/* //신착 상품 타이틀 */}
                {/* {lineSize === 2 && idx === 0 && getDateDifference(new Date(data.createTime), new Date()) < 7 &&
              <div
              style={{
                height:"20px",
                display:"block",
              }}
              
              >가나다라</div>} */}
                <div
                  style={{
                    display: "flex",
                    //   padding : "5px",
                    //중앙정렬
                    //   display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key="container"
                >
                  {idx < resDataList.length && (
                    <ProductListNode
                      isSales={data.isSales}
                      detailImgUrlList={getDetailImgList(
                        data.compactThumbnailImgUrl,
                        data.detailImgUrlList
                      )}
                      createTime={data.createTime}
                      onCartClick={this.onCartClick}
                      isTradingProduct={data.isTradingProduct}
                      lineSize={lineSize}
                      key={data.id}
                      id={data.id}
                      name={data.name}
                      status={data.status}
                      isSoldOut={
                        // data.stock > 0 ? 0 : 1

                        parseInt(data.isSoldOut)
                      }
                      thumbnailImgUrl={data.thumbnailImgUrl}
                      compactThumbnailImgUrl={data.compactThumbnailImgUrl}
                      releaseDate={data.releaseDate}
                      deadlineDate={data.deadlineDate}
                      arrivalDate={data.arrivalDate}
                      originalPrice={data.originalPrice}
                      reservationPrice={data.reservationPrice}
                      arrivalPrice={data.arrivalPrice}
                      isWish={data.isWish}
                      isOptionable={data.isOptionable}
                      animeId={data.animeId}
                      animeName={data.animeName}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => this.onClick(data)}
                    />
                  )}
                  {/* //세번째노드 */}
                  {lineSize > 2 && idx + lineSize - 2 < resDataList.length && (
                    <ProductListNode
                      isSales={resDataList[idx + lineSize - 2].isSales}
                      detailImgUrlList={getDetailImgList(
                        resDataList[idx + lineSize - 2].compactThumbnailImgUrl,
                        resDataList[idx + lineSize - 2].detailImgUrlList
                      )}
                      createTime={resDataList[idx + lineSize - 2].createTime}
                      isTradingProduct={
                        resDataList[idx + lineSize - 2].isTradingProduct
                      }
                      onCartClick={this.onCartClick}
                      lineSize={lineSize}
                      key={resDataList[idx + lineSize - 2].id}
                      id={resDataList[idx + lineSize - 2].id}
                      name={resDataList[idx + lineSize - 2].name}
                      status={resDataList[idx + lineSize - 2].status}
                      isSoldOut={
                        // resDataList[idx + lineSize - 2].stock > 0 ? 0 : 1
                        parseInt(resDataList[idx + lineSize - 2].isSoldOut)
                      }
                      thumbnailImgUrl={
                        resDataList[idx + lineSize - 2].thumbnailImgUrl
                      }
                      compactThumbnailImgUrl={
                        resDataList[idx + lineSize - 2].compactThumbnailImgUrl
                      }
                      releaseDate={resDataList[idx + lineSize - 2].releaseDate}
                      deadlineDate={
                        resDataList[idx + lineSize - 2].deadlineDate
                      }
                      arrivalDate={resDataList[idx + lineSize - 2].arrivalDate}
                      originalPrice={
                        resDataList[idx + lineSize - 2].originalPrice
                      }
                      reservationPrice={
                        resDataList[idx + lineSize - 2].reservationPrice
                      }
                      arrivalPrice={
                        resDataList[idx + lineSize - 2].arrivalPrice
                      }
                      isWish={resDataList[idx + lineSize - 2].isWish}
                      isOptionable={
                        resDataList[idx + lineSize - 2].isOptionable
                      }
                      animeId={resDataList[idx + lineSize - 2].animeId}
                      animeName={resDataList[idx + lineSize - 2].animeName}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => this.onClick(resDataList[idx + lineSize - 2])}
                    />
                  )}
                  {idx + lineSize - 1 < resDataList.length && (
                    <>
                      <ProductListNode
                        isSales={resDataList[idx + lineSize - 1].isSales}
                        detailImgUrlList={getDetailImgList(
                          resDataList[idx + lineSize - 1]
                            .compactThumbnailImgUrl,
                          resDataList[idx + lineSize - 1].detailImgUrlList
                        )}
                        createTime={resDataList[idx + lineSize - 1].createTime}
                        onCartClick={this.onCartClick}
                        isTradingProduct={
                          resDataList[idx + lineSize - 1].isTradingProduct
                        }
                        lineSize={lineSize}
                        key={resDataList[idx + lineSize - 1].id}
                        id={resDataList[idx + lineSize - 1].id}
                        name={resDataList[idx + lineSize - 1].name}
                        status={resDataList[idx + lineSize - 1].status}
                        isSoldOut={
                          // resDataList[idx + lineSize - 1].stock > 0 ? 0 : 1
                          parseInt(resDataList[idx + lineSize - 1].isSoldOut)
                        }
                        thumbnailImgUrl={
                          resDataList[idx + lineSize - 1].thumbnailImgUrl
                        }
                        compactThumbnailImgUrl={
                          resDataList[idx + lineSize - 1].compactThumbnailImgUrl
                        }
                        releaseDate={
                          resDataList[idx + lineSize - 1].releaseDate
                        }
                        deadlineDate={
                          resDataList[idx + lineSize - 1].deadlineDate
                        }
                        arrivalDate={
                          resDataList[idx + lineSize - 1].arrivalDate
                        }
                        originalPrice={
                          resDataList[idx + lineSize - 1].originalPrice
                        }
                        reservationPrice={
                          resDataList[idx + lineSize - 1].reservationPrice
                        }
                        arrivalPrice={
                          resDataList[idx + lineSize - 1].arrivalPrice
                        }
                        isWish={resDataList[idx + lineSize - 1].isWish}
                        isOptionable={
                          resDataList[idx + lineSize - 1].isOptionable
                        }
                        animeId={resDataList[idx + lineSize - 1].animeId}
                        animeName={resDataList[idx + lineSize - 1].animeName}
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => this.onClick(resDataList[idx + lineSize - 1])}
                      />
                    </>
                  )}
                </div>
                {/* {lineSize === 2 && idx + lineSize - 1 < resDataList.length && getDateDifference(new Date(resDataList[idx + lineSize - 1].createTime), new Date()) < 7 &&
              resDataList[idx + lineSize] &&
              getDateDifference(new Date(resDataList[idx + lineSize].createTime), new Date()) >= 7 &&
              <div
              style={{
                height:"20px",
                display:"block",
              }}
              
              >가나다라</div>} */}
              </>
            ) : (
              <></>
            )
          )}
        </>
      ) : (
        <>
          <div>
            <h3>상품이 없습니다.</h3>
          </div>
        </>
      )
    ) : (
      <></>
    );
  };

  checkProductCreatedTime = (
    data: ProductListPropsType,
    start: number,
    end: number
  ) => {
    const dateDifference = Math.abs(
      getDateDifference(new Date(data.createTime), new Date())
    );

    if (dateDifference >= start && dateDifference < end) {
      return true;
    }
    return false;
  };

  render() {
    this.onCartClick = this.onCartClick.bind(this);
    this.onCartToggleDrawer = this.onCartToggleDrawer.bind(this);
    this.printProductList = this.printProductList.bind(this);
    this.printTitle = this.printTitle.bind(this);
    this.checkProductCreatedTime = this.checkProductCreatedTime.bind(this);

    const lineSize = this.props.lineSize ? this.props.lineSize : 2;
    return (
      <div>
        {this.props.categoryId === undefined &&
        this.props.type !== Constants.PRODUCT_FETCH_LIST_TYPE.BEST_SELLER &&
        this.props.isWishList === undefined &&
        this.props.isSearch === undefined ? (
          <>
            {this.state.resData &&
              this.state.resData.length > 0 &&
              this.printProductList(
                this.state.resData.filter(
                  (data) =>
                    Math.abs(
                      getDateDifference(new Date(), new Date(data.createTime))
                    ) === 0
                ).length > 0
                  ? this.state.resData.filter(
                      (data) =>
                        Math.abs(
                          getDateDifference(
                            new Date(),
                            new Date(data.createTime)
                          )
                        ) === 0
                    )
                  : null,
                // 데이터가 없으면 상품이없습니다 도 출력하지않는다. => 기본적으로는 출력되도록 되어있음.
                lineSize,
                this.printTitle(
                  "《 오늘의 " +
                    (this.props.type ===
                    Constants.PRODUCT_FETCH_LIST_TYPE.RESERVATION
                      ? "예약 상품"
                      : "신상") +
                    " 》"
                )
              )}
            {this.state.resData &&
              this.state.resData.length > 0 &&
              this.printProductList(
                this.state.resData.filter((data) =>
                  this.checkProductCreatedTime(data, 1, 7)
                ).length > 0
                  ? this.state.resData.filter((data) =>
                      this.checkProductCreatedTime(data, 1, 7)
                    )
                  : null,
                // 데이터가 없으면 상품이없습니다 도 출력하지않는다. => 기본적으로는 출력되도록 되어있음.
                lineSize,
                this.printTitle(
                  "《 이 주의 " +
                    (this.props.type ===
                    Constants.PRODUCT_FETCH_LIST_TYPE.RESERVATION
                      ? "예약 상품"
                      : "신상") +
                    " 》"
                )
              )}
            {this.state.resData &&
              this.state.resData.length > 0 &&
              this.printProductList(
                this.state.resData.filter(
                  (data) =>
                    Math.abs(
                      getDateDifference(new Date(), new Date(data.createTime))
                    ) >= 7
                ),
                lineSize,
                this.printTitle(
                  this.props.type ===
                    Constants.PRODUCT_FETCH_LIST_TYPE.NEW_ARRIVAL
                    ? "《 신상품 》"
                    : this.props.type ===
                      Constants.PRODUCT_FETCH_LIST_TYPE.RESERVATION
                    ? "《 예약 상품 》"
                    : this.props.type ===
                      Constants.PRODUCT_FETCH_LIST_TYPE.RECOMMEND
                    ? "《 추천 상품 》"
                    : "《 베스트 상품 》"
                )
              )}
          </>
        ) : (
          <>
            {this.props.type == Constants.PRODUCT_FETCH_LIST_TYPE.BEST_SELLER &&
              this.state.resData &&
              this.state.resData.length > 0 &&
              this.printProductList(this.state.resData, lineSize, null)}

            {this.props.categoryId &&
              this.state.resData &&
              this.state.resData.length > 0 &&
              this.printProductList(this.state.resData, lineSize, null)}

            {this.props.isWishList &&
              this.state.resData &&
              this.printProductList(this.state.resData, lineSize, null)}

            {this.props.isSearch &&
              this.state.resData &&
              this.printProductList(this.state.resData, lineSize, null)}
          </>
        )}

        {/* {this.state.resData &&this.printProductList(this.state.resData, lineSize)} */}

        {this.state.drawerOpen === true && (
          <ProductDetailDrawer
            isSales={0}
            deadlineDate={undefined}
            maximumOrderStock={0}
            isSoldOut={0}
            isWish={0}
            isTaxFree={0}
            onCartToggleDrawer={this.onCartToggleDrawer}
            isProductListCall={true}
            id={this.state.selectedProductId}
            name={""}
            //animeName의 뒤에서 첫번째 공백 앞까지 자르기
            title={""}
            compactThumbnailImgUrl={""}
            status={0}
            setItemStock={0}
            reservationPrice={0}
            arrivalPrice={0}
            arrivalDate={undefined}
            deliveryFee={Constants.DELIVERY_FEE}
            releaseDate={undefined}
            isArrived={false}
            wishCount={0}
          />
        )}

        <div
          ref={this.loadingElementRef}
          style={{
            height: "20px",
            margin: "10px",
            background: "transparent",
          }}
        >
          {/* 여기에 교차 여부를 감지할 요소(일반적으로 로딩 스피너 또는 힌트 메시지 등)를 추가할 수 있습니다. */}
        </div>
      </div>
    );
  }
}

export default withRouter(ProductList);
// export default ProductList;
// export default connector(ProductList);
