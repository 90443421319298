import Link from "next/link";
import React, { Component } from "react";
import { secondary } from "@/styles/theme";

import divider from "@/res/drawable/divider.png";

class Footer extends Component {
  render() {
    const isProductDetail = location.href.includes("/product/detail")
      ? true
      : false;

    return (
      !location.href.includes("/profile") == true &&
      // !location.href.includes("/product/detail") == true &&
      !location.href.includes("/category/board") == true && (
        <div
          style={{
            marginBottom: isProductDetail ? "70px" : "20px",
            textAlign: "center",
          }}
        >
          <img
            src={divider.src}
            style={{
              width: "80%",
              height: "auto",
              marginBottom: "50px",
            }}
          />
          <div
            style={{
              display: "block",
              // height: "100px",
              backgroundColor: "rgba(135, 104, 188, 0.7)",
              textAlign: "left",
              padding: "27px",
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                color: "white",
                fontSize: "11px",
                // size:"2",
                whiteSpace: "pre-line",
                fontFamily: "subTitle",
                lineHeight: "1.5",
              }}
            >
              <span style={{ color: secondary }}> 사업자 정보 : </span>{" "}
              {"(주)키라키라토모"}
              <br></br>
              <span style={{ color: secondary }}> 사업자 등록번호 : </span>
              845-86-03291
              <span style={{ color: secondary }}> 대표자 : </span>최성의
              <br></br>
              <span style={{ color: secondary }}> 주소 : </span>
              {"(18469) "}경기도 화성시 동탄첨단산업1로 27, B동 14층 B1424호
              <br></br>
              B1424, 27, Dongtancheomdansaneop 1-ro, Hwaseong-si, Gyeonggi-do
              <br></br>
              <span style={{ color: secondary }}> 통신판매업신고 :</span>
              2025-화성동탄-0190
              <br></br>
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              textAlign: "center",
              fontSize: "0.8rem",
            }}
            onClick={(e) => {
              location.href = "/siteRule";
            }}
          >
            <b>이용 약관 확인</b>
          </div>
          {" / "}
          <div
            style={{
              display: "inline-block",
              textAlign: "center",
              fontSize: "0.8rem",
            }}
            onClick={(e) => {
              location.href = "/refundRule";
            }}
          >
            <b>교환 환불 정책</b>
          </div>
          <div
            style={{
              padding: "15px",
              textAlign: "center",
              fontSize: "0.7rem",
            }}
          >
            「키라키라토모」에서는 개인정보를 암호화하여 안전하게 보관하고
            있습니다.
          </div>
        </div>
      )
    );
  }
}

export default Footer;
