require("dotenv").config();

import "@/styles/globals.css";
import type { AppProps } from "next/app";
import MainTheme from "@/styles/theme";

import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/styles";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AppBar, Fab, InputBase, Toolbar } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { CustomStore } from "../redux/CustomStore";
import { persistor } from "../redux/CustomStore";
import { styled, alpha } from "@mui/material/styles";
import dynamic from "next/dynamic";
import Footer from "@/component/molecules/Footer";

export default function App({ Component, pageProps }: AppProps) {
  const store = CustomStore;

  const [value, setValue] = React.useState(1);
  const [swiper, setSwiper] = React.useState(null);

  /* 동적 import 함. window 객체사용을 위함 */
  const UpButtonCSR = dynamic(() => import("../component/atoms/UpButtonCSR"), {
    ssr: false,
  });

  /* 동적 import 함. window 객체사용을 위함 */
  const Header = dynamic(
    () => import("@/component/orhanisms/HeaderComponent"),
    {
      ssr: false,
    }
  );
  return (
    <ThemeProvider theme={MainTheme}>
      {/* // 스크롤이 최상단이 아닌 경우에만 출력 */}
      <UpButtonCSR />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div
            style={{
              display: "block",
              margin: 0,
              padding: 0,
              marginBottom: "40px",
            }}
          >
            <Header />
            <div
              className="App"
              style={{
                /* app bar  */
                marginTop: "50px",
                marginBottom: "30px",
                overflowX: "hidden",
              }}
            >
              <Component {...pageProps} />
            </div>

            <Footer />
          </div>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
