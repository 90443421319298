import pica from "pica";
import { get, getSSR, post } from "../redux/module/api";
import {
  CategoryNodePropsType,
  ProductDetailCardNode,
  ProductDetailImg,
  ProductListPropsType,
} from "@/types/ProductTypes";
import { useRouter } from "next/router";
import {
  ExhibitionPropsType,
  OrderListNodePropsType,
  OrderProductNodePropsType,
} from "@/types/PropsTypes";
import { useEffect, useState } from "react";

export interface MetaData {
  title: string;
  description: string;
  image: string;
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 사용자 에이전트에서 모바일 장치 여부를 확인하는 정규식
    const checkMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    setIsMobile(checkMobile);
  }, []);

  return isMobile;
}
export function isBirthdateValid(birthdate: string) {
  const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  //return false or true
  return pattern.test(birthdate);
}
export function getArrivalPrice(price: number) {
  switch (price) {
    case 275:
      return 4000;
    case 300:
      return 4000;
    case 330:
      return 4500;
    case 400:
      return 5500;
    case 440:
      return 6000;
    case 500:
      return 6500;
    case 550:
      return 7000;
    case 600:
      return 7500;
    case 660:
      return 8500;
    case 700:
      return 9000;
    case 715:
      return 9000;
    case 770:
      return 9800;
    case 800:
      return 10000;
    case 880:
      return 11000;
    case 900:
      return 11500;
    case 990:
      return 12500;
    case 1000:
      return 13000;
    case 1100:
      return 14000;
    case 1210:
      return 15500;
    case 1320:
      return 17000;
    case 1430:
      return 18500;
    case 1540:
      return 20000;
    case 1650:
      return 21000;
    case 1760:
      return 22500;
    case 1870:
      return 24000;
    case 1980:
      return 25000;
    default:
      // price * 12.7 값의 십의 자리에서 올림
      return Math.ceil((price * 12.7) / 100) * 100;
  }
}

export const getTimeDifference = (datetime: string): string => {
  const postDate = new Date(datetime);
  const now = new Date();

  // 현재 시간과 게시 시간의 차이를 밀리초로 계산
  const diffInMs = now.getTime() - postDate.getTime();

  // 밀리초를 분, 시간, 일로 변환
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  // 1달 이상 차이 계산
  const diffInMonths =
    now.getMonth() -
    postDate.getMonth() +
    (now.getFullYear() - postDate.getFullYear()) * 12;

  if (diffInMonths >= 1) {
    // 같은 해일 경우 년도 생략
    const isSameYear = now.getFullYear() === postDate.getFullYear();

    const formattedDate = isSameYear
      ? `${String(postDate.getMonth() + 1).padStart(2, "0")}월 ${String(
          postDate.getDate()
        ).padStart(2, "0")}일 ${String(postDate.getHours()).padStart(
          2,
          "0"
        )}:${String(postDate.getMinutes()).padStart(2, "0")}`
      : `${postDate.getFullYear()}년 ${String(postDate.getMonth() + 1).padStart(
          2,
          "0"
        )}월 ${String(postDate.getDate()).padStart(2, "0")}일 ${String(
          postDate.getHours()
        ).padStart(2, "0")}:${String(postDate.getMinutes()).padStart(2, "0")}`;

    return formattedDate;
  }

  if (diffInDays >= 1) {
    // 1일 이상일 경우 'x일 전' 출력
    // const remainingHours = diffInHours % 24;
    return `${diffInDays}일`;
    // return `${diffInDays}일 ${remainingHours}시간 전`;
  }

  if (diffInHours >= 1) {
    // 1시간 이상일 경우 'x시간 전' 출력
    // const remainingMinutes = diffInMinutes % 60;
    // return `${diffInHours}시간 ${remainingMinutes}분`;
    return `${diffInHours}시간`;
  }

  if (diffInMinutes >= 1) {
    // 1분 이상일 경우 'x분 전' 출력
    // const remainingSeconds = diffInSeconds % 60;
    return `${diffInMinutes}분`;
  }

  // 1분 미만일 경우 'x초 전' 출력
  return `${diffInSeconds}초`;
};

// 예시 사용
// const result1 = getTimeDifference("2024-08-01T12:30:00"); // 2024년 같은 해에 해당하는 경우
// const result2 = getTimeDifference("2023-08-01T12:30:00"); // 2023년 다른 해에 해당하는 경우
// const result3 = getTimeDifference("2024-09-15T12:30:00"); // 몇시간, 몇분 전

// console.log(result1); // '08월 01일 12:30' (같은 해이므로 년 생략)
// console.log(result2); // '2023년 08월 01일 12:30' (다른 해)
// console.log(result3); // '몇시간 몇분 전'

//F12 및 개발자 옵션 막기
export const disableDevTools = () => {
  const handler = (event: KeyboardEvent) => {
    // F12 막기
    if (event.key === "F12") {
      event.preventDefault();
    }

    // Ctrl + Shift + I 막기
    if (event.ctrlKey && event.shiftKey && event.key === "I") {
      event.preventDefault();
    }
  };

  window.addEventListener("keydown", handler);

  // 컴포넌트 언마운트 시 이벤트 리스너 제거
  return () => {
    window.removeEventListener("keydown", handler);
  };
};

export const checkAllCart = async () => {
  try {
    const res = await get("cart/check", {});

    if (res.status === 200) {
      return "";
    }
  } catch (e: any) {
    if (e.response.status == 400) {
      return e.response.data;
    }
  }
};

export function getGuestIdStorage() {
  if (!localStorage.hasOwnProperty("guestId")) {
    localStorage.setItem("guestId", createGuestId());
  }

  return localStorage.getItem("guestId");
}

export function createGuestId() {
  //7자리 랜덤 숫자 생성
  const randomNum = Math.floor(Math.random() * 10000000);

  //guestId는 111으로 시작한다
  return ("111" + randomNum) as string;
}
export async function fetchMetaData(url: string, cookies: any) {
  let metaData: MetaData = {
    title: "키라키라토모 - 신개념 애니굿즈샵",
    description: "신개념 애니 굿즈샵! 꿈꾸는 어른이의 원더랜드, 키라키라토모",
    image: "https://kirakira-tomo.com/_next/static/media/logo.b9f2a45a.png",
  };

  if (url.includes("/product/detail/")) {
    //'/product/detail/' 뒤에있는 id를 추출
    const id = url.split("/product/detail/")[1].split("/")[0];

    //상품 정보
    const res = await getSSR("product/" + id, {}, cookies);

    try {
      if (res.status == 200) {
        const resData = res.data.resData as ProductDetailCardNode;
        metaData.title =
          "《" +
          resData.animeName +
          "》 " +
          resData.name +
          " - " +
          (resData.status === 1
            ? addDot(resData.reservationPrice) + "원"
            : addDot(resData.arrivalPrice) + "원") +
          " 【키라키라토모】";

        metaData.description = resData.description;
        metaData.image = resData.compactThumbnailImgUrl;
      }
    } catch (e) {
      console.log(e);
    }
  } else if (url.includes("/category/")) {
    const id = url.split("/category/")[1].split("/")[0];
    const res = await getSSR("product/category/detail/" + id, {}, cookies);

    try {
      if (res.status == 200) {
        const resData = res.data.resData as CategoryNodePropsType;
        metaData.title =
          "《" + resData.name + "》 상품 리스트 - 애니굿즈샵 【키라키라토모】";

        metaData.description = "";
        if (resData.compactThumbnailUrl) {
          metaData.image = resData.compactThumbnailUrl;
        }
      }
    } catch (e) {
      console.log(e);
    }
  } else if (url.includes("/exhibition/")) {
    const id = url.split("/exhibition/")[1].split("/")[0];
    const res = await getSSR("exhibition/" + id, {}, cookies);

    try {
      if (res.status == 200) {
        const resData = res.data.resData as ExhibitionPropsType;
        metaData.title =
          "《기획전》 " + resData.title + " - 애니굿즈샵 【키라키라토모】";

        metaData.description = resData.content;
        if (resData.image) {
          metaData.image = resData.image;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  return metaData;
}

export const getDetailImgList = (
  compactThumbnailImgUrl: string,
  detailImgUrlList: ProductDetailImg[]
) => {
  const detailImgUrlListInit = new Array<ProductDetailImg>();

  detailImgUrlListInit.push({
    id: 0,

    imgUrl: "",

    productId: 0,
    originalImgUrl: "",
    thumbnailImgUrl: compactThumbnailImgUrl,
  });

  if (detailImgUrlList && detailImgUrlList.length > 0) {
    return detailImgUrlListInit.concat(detailImgUrlList);
  }
  return detailImgUrlListInit;
};
export function doCopy(text: any) {
  // 흐음 1.
  if (navigator.clipboard) {
    // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
    if (window.confirm("URL을 클립보드에 복사하시겠습니까?") === true) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("URL이 클립보드에 복사되었습니다.");
        })
        .catch(() => {
          alert("복사를 다시 시도해주세요.");
        });
    } else {
      return;
    }
  } else {
    // 흐름 2.
    if (!document.queryCommandSupported("copy")) {
      return alert("복사하기가 지원되지 않는 브라우저입니다.");
    }

    // 흐름 3.
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.top = "0";
    textarea.style.left = "0";
    textarea.style.position = "fixed";

    // 흐름 4.
    document.body.appendChild(textarea);
    // focus() -> 사파리 브라우저 서포팅
    textarea.focus();
    // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
    textarea.select();
    // 흐름 5.
    document.execCommand("copy");
    // 흐름 6.
    document.body.removeChild(textarea);
    alert("URL이 클립보드에 복사되었습니다.");
  }
}
// import randomColor from "randomcolor";
export function getCurrentDate() {
  let separator = "-";
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  // 날짜와 월이 1자리일 때 0을 추가
  let formattedDate = date < 10 ? `0${date}` : `${date}`;
  let formattedMonth = month < 10 ? `0${month}` : `${month}`;

  return `${year}${separator}${formattedMonth}${separator}${formattedDate}`;
}

export const setMetaTags = (
  title: string,
  description: string,
  imageUrl: string,
  document: any
) => {
  //set title
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", title);

  //set description
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", description);

  //set images
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", imageUrl);

  //set url
  // document
  //   .querySelector('meta[property="og:url"]')
  //   .setAttribute("content", window.location.href);
};
interface FormDataObject {
  [key: string]: string | File;
}
export const ORDER_STATUS_ALL = "ALL";
export const ORDER_STATUS_PAID = "PAID";
export const ORDER_STATUS_PREPARING = "PREPARING";
export const ORDER_STATUS_SHIP_READY = "SHIP_READY";
export const ORDER_STATUS_SHIPPING = "SHIPPING";
export const ORDER_STATUS_SHIPPED = "SHIPPED";
export const ORDER_STATUS_CANCELLED = "CANCELLED";

export const TEST_SERVER = "new.kirakiratomo.com";

export const ORDER_STATUS = [
  "ALL",
  "PAID",
  "PREPARING",
  "SHIP_READY",
  "SHIPPING",
  "SHIPPED",
  "CANCELLED",
];
export const getValueFromInput = (elementId: string) => {
  return (document.getElementById(elementId) as HTMLInputElement)?.value;
};

export const getElementFromInput = (elementId: string) => {
  return document.getElementById(elementId) as HTMLInputElement;
};
export const isAdminUser = async () => {
  try {
    const response = await get("login/isAdmin", {});
    return true;
  } catch (e) {
    return false;
  }
};

// Function to add a recently viewed product and store it in local storage
export const addRecentlyViewedProduct = (product: ProductListPropsType) => {
  // Get the current list of recently viewed products from local storage
  const storedRecentlyViewed = localStorage.getItem("recentlyViewedProducts");

  let recentlyViewed: ProductListPropsType[] = [];
  if (storedRecentlyViewed) {
    recentlyViewed = JSON.parse(storedRecentlyViewed);
  }

  // Check if the product with the same id already exists in the list
  const existingProductIndex = recentlyViewed.findIndex(
    (item) => item.id === product.id
  );

  // If the product already exists, remove it from the list
  if (existingProductIndex !== -1) {
    recentlyViewed.splice(existingProductIndex, 1);
  }

  // Add the product to the beginning of the list
  recentlyViewed.unshift(product);

  // Limit the list to a maximum of 20 items
  const updatedRecentlyViewed = recentlyViewed.slice(0, 20);

  // Save the updated list to local storage
  localStorage.setItem(
    "recentlyViewedProducts",
    JSON.stringify(updatedRecentlyViewed)
  );
};

export const getPrice = (
  status: number,
  arrivalPrice: number,
  reservationPrice: number
) => {
  if (status === 1) {
    return reservationPrice;
  } else if (status === 2) {
    return arrivalPrice;
  }
};

// 주어진 색상 코드의 채도와 명도를 유지하면서 랜덤 색상 코드를 생성하는 함수
export const generateRandomColorWithOriginal = (colorCode: string): string => {
  // 주어진 색상 코드를 HSL로 변환
  const hexToHSL = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return { h, s, l };
  };

  // HSL 색상으로 변환
  const { h, s, l } = hexToHSL(colorCode);

  // 랜덤한 Saturation과 Lightness 값을 생성 (채도와 명도 유지)
  const randomS = Math.random();
  const randomL = Math.random();

  // HSL 색상을 RGB 색상으로 변환
  const hslToHex = (h: number, s: number, l: number) => {
    const hueToRGB = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRGB(p, q, h + 1 / 3);
      g = hueToRGB(p, q, h);
      b = hueToRGB(p, q, h - 1 / 3);
    }

    const toHex = (x: number) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const randomColorCode = hslToHex(h, randomS, randomL);

  return randomColorCode;
};

export const isLoginUser = async () => {
  try {
    const response = await get("login/verify", {});
    return true;
  } catch (e) {
    return false;
  }

  // return false;
};

export const getOrderStatusString = (status: string): string => {
  switch (status) {
    case "PAID":
      return "결제완료";
    case "PREPARING":
      return "상품준비중";

    case "SHIP_READY":
      return "배송준비중";
    case "SHIPPED":
      return "배송중";
    case "DELIVERED":
      return "배송완료";
    case "CANCELLED":
      return "주문취소";
    default:
      return "주문상태";
  }
};

export const getDayString = (date: Date): string => {
  return (
    {
      0: "일",
      1: "월",
      2: "화",
      3: "수",
      4: "목",
      5: "금",
      6: "토",
    }[date ? date.getDay() : 0] || ""
  );
};

export const isOrderNumString = (input: string): boolean => {
  const pattern = /^(\d{8}-){2}\d{3}(-\d{8})?$/;
  return pattern.test(input);
};

export const isAvailableStock = (isSoldout: number, isSales: number) => {
  if (isSoldout === 1 || isSales === 0) {
    return false;
  } else {
    return true;
  }
};

export const isAvailableReservation = (
  deadlineDate: string,
  isSoldOut: number,
  isSales: number
) => {
  if (
    getDateDifference(new Date(deadlineDate), new Date()) < 0 ||
    isSoldOut === 1 ||
    isSales === 0
  ) {
    return false;
  } else {
    return true;
  }
};
// 스크롤 위치 저장
export const saveScrollPositionOnly = () => {
  localStorage.setItem("scrollPosition", window.scrollY.toString());
  console.log(window.scrollY.toString());
};

// 스크롤 위치 저장
export const saveScrollPosition = (page: number) => {
  localStorage.setItem("scrollPositionPage", page + "");
  localStorage.setItem("scrollPosition", window.scrollY.toString());
  console.log(window.scrollY.toString());
};

export const handleDetailRouter = (router: any, url: string) => {
  router.push({ pathname: url });
};

export const restoreScrollPositionPage = () => {
  const scrollPositionPage = localStorage.getItem("scrollPositionPage");
  console.log(scrollPositionPage);
  return parseInt(
    scrollPositionPage !== undefined ? scrollPositionPage + "" : "0"
  );
};

// 저장된 스크롤 위치 복원
export const restoreScrollPosition = async () => {
  const scrollPosition = localStorage.getItem("scrollPosition");
  if (
    localStorage.hasOwnProperty("scrollPosition") &&
    scrollPosition !== "" &&
    scrollPosition !== undefined &&
    scrollPosition !== null
  ) {
    console.log(scrollPosition);

    if (scrollPosition) {
      //1초 마다 스크롤 이동하고, 현재 스크롤 위치가 이동 스크롤 위치가 거의 같을 때까지 반복

      const interval = setInterval(() => {
        window.scrollTo(0, parseInt(scrollPosition));
        if (Math.abs(window.scrollY - parseInt(scrollPosition)) < 10) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }
};

// export const handleRouteChange = (url: string) => {
//   console.log("handleRouteChange");
//   if (url === router.asPath) {
//     restoreScrollPosition();
//   }
// };

export const isOrderPossible = async (productId: number, quantity: number) => {
  try {
    const res = await get("order/possible/" + productId + "/" + quantity, {});
    console.log(res);
  } catch (e: any) {
    console.log(e);
    if (e.response.status == 400) {
      alert(e.response.data);

      // if (e.response.data === "NO_PRODUCT" || e.response.data === "INVISIBLE") {
      //   alert("잘못 된 요청입니다.");
      //   location.href = "/";
      // } else if (e.response.data === "NO_STOCK") {
      //   alert("상품이 품절 되었습니다.");
      //   window.location.reload();
      // } else if (e.response.data === "OVER_MAX_QUANTITY") {
      //   alert("주문 수량이 상품의 재고 수량보다 많습니다.");
      // } else if (e.response.data === "OVER_MAXIMUM_ORDER_QUANTITY") {
      //   alert("주문 최대 수량을 초과했습니다.");
      // } else {
      //   alert("Internal server error");
      //   window.location.reload();
      // }
    } else {
      alert("Internal server error");
      location.reload();
    }
    return false;
  }

  return true;
};

export const getCounselStatusNumber = (category: string): number => {
  switch (category) {
    case "배송문의":
      return 1;
    case "주문문의":
      return 2;
    case "결제문의":
      return 3;
    case "교환/반품":
      return 4;
    case "시스템 오류":
      return 5;
    case "ETC":
      return 6;
    default:
      return 0;
  }
};
export const getCounselStatusStringDesc = (category: number): string => {
  switch (category) {
    case 1:
      return "배송문의";
    case 2:
      return "주문문의";
    case 3:
      return "결제문의";
    case 4:
      return "교환/반품";
    case 5:
      return "시스템 오류";
    case 6:
      return "ETC";
    default:
      return "주문상태";
  }
};

export const uploadExcel = async (list: any[]) => {
  //엑셀파일 업로드

  var formData = new FormData();
  list.map((item: any, index: number) => {
    formData.append("list[" + index + "].orderNum", item.orderNum);

    formData.append("list[" + index + "].deliveryNumber", item.deliveryNumber);
  });

  let res = undefined;
  try {
    res = await post("orderDelivery/update", formData, {});

    if (res.status === 200) {
      uploadCallback(res);
    }
  } catch (e: any) {
    errorCallBack(e);
  }
};

const uploadCallback = (response: any) => {
  console.log(response);
  alert("운송장 업로드 성공");
  window.location.reload();
};

const errorCallBack = (error: any) => {
  console.log(error);
  alert("운송장 업로드 실패");
};

export const createExcel = (orderInfoList: OrderListNodePropsType[]) => {
  const Excel = require("exceljs");
  const workbook = new Excel.Workbook();

  // 생성자
  workbook.creator = "작성자";

  // 최종 수정자
  workbook.lastModifiedBy = "최종 수정자";

  // 생성일(현재 일자로 처리)
  workbook.created = new Date();

  // 수정일(현재 일자로 처리)
  workbook.modified = new Date();

  workbook.addWorksheet("시트1");
  // 엑셀 시트 접근(어떤 워크시트에서 작업할 건지...)
  const sheetOne = workbook.getWorksheet("시트1");

  // ===================== 컬럼 설정 =====================
  // header: 엑셀에 표기되는 이름
  // key: 컬럼을 접근하기 위한 key
  // hidden: 숨김 여부
  // width: 컬럼 넓이
  // style: 셀 스타일 설정
  sheetOne.columns = [
    { header: "예약구분", key: "type", width: 5 }, // 2번째열
    { header: "집하예정일", key: "startDate", width: 10 }, // 2번째열

    { header: "수령인", key: "deliveryName", width: 10 }, // 3번째열
    { header: "연락처", key: "deliveryPhone", width: 20 }, //4번째,5번째
    { header: "연락처2", key: "deliveryPhone2", width: 5 }, //4번째,5번째
    { header: "우편번호", key: "deliveryPostcode", width: 10 }, //6번째 열
    { header: "배송지", key: "deliveryAddr", width: 60 }, //7번째 열

    { header: "운송장번호", key: "deliveryNumber", width: 20 },

    { header: "주문번호", key: "orderNum", width: 20 }, //9번째열

    {
      header: "주문상품/쿠지결과", //10번째열
      key: "orderProduct",
      hidden: false,
      width: 50,
    },

    { header: "박스수량", key: "boxNum", width: 5 },
    { header: "박스타입", key: "boxType", width: 5 },
    { header: "기본운임", key: "deliveryFee", width: 5 },

    // { header: "택배사", key: "deliveryCompany", width: 10 },
    //배송메세지

    { header: "배송메세지", key: "deliveryMessage", width: 30 }, // 14번째열
  ];

  const orderNum = sheetOne.getColumn("orderNum");
  const orderProduct = sheetOne.getColumn("orderProduct");
  const deliveryAddr = sheetOne.getColumn("deliveryAddr");
  const deliveryPostcode = sheetOne.getColumn("deliveryPostcode");
  const deliveryName = sheetOne.getColumn("deliveryName");
  const deliveryPhone = sheetOne.getColumn("deliveryPhone");
  const deliveryPhone2 = sheetOne.getColumn("deliveryPhone2");

  const deliveryNumber = sheetOne.getColumn("deliveryNumber");
  const deliveryMessage = sheetOne.getColumn("deliveryMessage");

  const boxNum = sheetOne.getColumn("boxNum");
  const boxType = sheetOne.getColumn("boxType");
  const deliveryFee = sheetOne.getColumn("deliveryFee");

  const type = sheetOne.getColumn("type");
  const startDate = sheetOne.getColumn("startDate");

  // const orderColumn = Object.keys(this.state.excelOrderInfoList); //칼럼리스트. 주문번호리스트임.

  orderInfoList.map((orderInfo: OrderListNodePropsType, index) => {
    orderInfo.orderProductList.map(
      (orderProduct: OrderProductNodePropsType, index) => {
        const resultString =
          "[" +
          orderProduct.productTitle +
          "]" +
          orderProduct.productName +
          " - " +
          orderProduct.quantity +
          "개";

        const data = {
          type: 1,
          startDate: "",
          deliveryNumber: "",
          boxNum: 1,
          boxType: 1,
          deliveryFee: "",
          orderNum: orderInfo.orderNum.substr(0, 17),
          orderProduct: "[" + +"]" + resultString,

          deliveryAddr: orderInfo.deliveryAddr1 + ", " +orderInfo.deliveryAddr2,
          deliveryPostcode: orderInfo.postcode,
          deliveryName: orderInfo.name,
          deliveryPhone: orderInfo.phone,
          deliveryPhone2: orderInfo.phone,
          deliveryMessage: orderInfo.deliveryMsg,
        };

        sheetOne.addRow(data);
      }
    );
  });

  workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    // 파일명
    anchor.download = `송장리스트.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export const getOrderStatusStringDesc = (status: string): string => {
  switch (status) {
    case "PAID":
      return "결제가 완료 된 상태입니다. 영업일 13시까지 배송지 변경 및 취소요청이 가능합니다. (예약 건의 경우 마감 전까지 가능)";
    case "PREPARING":
      return "주문 건에 대한 상품을 준비하고 있습니다.";
    case "SHIP_READY":
      return "현재 출고 작업 중으로, 택배사에서 상품을 수거 할 예정입니다.";
    case "SHIPPED":
      return "출고가 완료되었습니다. 수령까지 영업일 1-3일 소요됩니다.";
    case "DELIVERED":
      return "배송이 완료되었습니다.";
    case "CANCELLED":
      return "주문이 취소되었습니다. 환불까지 영업일 3-5일 소요됩니다.";
    case "REFUNDED":
      return "환불이 완료되었습니다. 카드사 처리까지 영업일 3-5일 소요될 수 있습니다.";
    case "CANCEL_REQUESTED":
      return "주문 취소 요청이 접수되었으며, 처리 중입니다.";
    case "REFUND_REQUESTED":
      return "환불 요청이 접수되었으며, 처리 중입니다.";
    default:
      return "주문상태";
  }
};

export const isNumeric = (input: string): boolean => {
  const numericRegex = /^[0-9]+$/;
  return numericRegex.test(input);
};
export function formatDateToYYYYMMDD(date: Date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth()).padStart(2, "0");
  // const mm = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줌
  const dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

export function getDateDifference(date1: Date, date2: Date): number {
  const timestamp1 = date1.setHours(0, 0, 0, 0); // date1의 타임스탬프를 오늘 날짜의 시작으로 설정
  const timestamp2 = date2.setHours(0, 0, 0, 0); // date2의 타임스탬프를 오늘 날짜의 시작으로 설정

  // const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1); // 차이(밀리초 단위)
  const differenceInMilliseconds = timestamp1 - timestamp2; // 차이(밀리초 단위)

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24); // 일 단위

  const roundedDifference = Math.floor(differenceInDays); // 소수값을 내림하여 정수로 반환

  if (roundedDifference === 1) {
    return 1;
  }

  return roundedDifference;
}
export function replaceSpacesAndSpecialCharacters(inputString: string): string {
  const modifiedString = inputString
    .replaceAll(/[^\w\d가-힣]+/g, "-") // 영문자, 숫자, 한글을 제외한 모든 문자를 "-"로 변경
    .replaceAll(/-{2,}/g, "-"); // "-"가 연속되어 나오면 하나로 축소

  return replaceSpacesAndSpecialCharactersToSpace(modifiedString);
}
export function replaceSpacesAndSpecialCharactersToSpace(
  inputString: string
): string {
  const modifiedString = inputString.replaceAll("-", " "); // "-"를 공백으로 변경

  return modifiedString;
}
export function getRandomNumberOrUndefined(): number | undefined {
  const options = [3, 4, 5, 6, 7, 8, 9, 10, undefined];
  const randomIndex = Math.floor(Math.random() * options.length);
  const randomNumberOrUndefined = options[randomIndex];
  return randomNumberOrUndefined;
}

export function getRGBAValue(color: string, alpha: number): string {
  const rgbValue = color.substring(1); // '#' 제거
  const red = parseInt(rgbValue.substring(0, 2), 16);
  const green = parseInt(rgbValue.substring(2, 4), 16);
  const blue = parseInt(rgbValue.substring(4, 6), 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export const createFormData = (data: FormDataObject): FormData => {
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      if (value instanceof File) {
        // 파일인 경우 append 처리
        formData.append(key, value);
      } else {
        // 문자열인 경우 append 처리
        formData.append(key, value);
      }
    }
  }

  return formData;
};

// export const hostUrl = "http://localhost:3000";
export const hostUrl = process.env.NEXT_PUBLIC_HOST_URL;

// export const SERVER_URL = "http://localhost:8080";
export const SERVER_URL = process.env.NEXT_PUBLIC_REACT_APP_API_URL;

export function addDot(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function imageDataToFile(imageData: string): File {
  const byteString = atob(imageData.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: "image/jpeg" });
  const file = new File([blob], "image.jpg", { type: blob.type });

  return file;
}

export function roundUpToNearest(number: number, nearest: number) {
  return Math.ceil(number / nearest) * nearest;
}

export default function getCurrentTimeStamp() {
  const timestamp = new Date();

  const year = timestamp.getFullYear();
  const month = timestamp.getMonth() + 1;
  const date = timestamp.getDate();

  const day = timestamp.getDay();

  const hour = timestamp.getHours();
  const minutes = timestamp.getMinutes();
  const seconds = timestamp.getSeconds();

  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (date < 10 ? "0" + date : date) +
    "T" +
    (hour < 10 ? "0" + hour : hour) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
}

// 위의 함수 roundUpToNearest는 첫 번째 인자로 처리할 숫자를 받고, 두 번째 인자로 어떤 값으로 올림할지 정하는 nearest 값을 받습니다. 이 함수는 주어진 숫자를 nearest 값의 배수로 나눈 후 올림을 수행하고, 그 결과에 다시 nearest 값을 곱하여 올림 처리된 값을 반환합니다.

// 예를 들어, roundUpToNearest(1525, 100)를 호출하면 결과로 1600을 반환합니다.

export async function resizeImage(
  inputImage: any,
  size: number,
  onResizedImage: any,
  canvasRef: any
) {
  canvasRef.current.width = 0;
  canvasRef.current.height = 0;

  const img = new Image();

  console.log(inputImage);
  img.src = URL.createObjectURL(inputImage);

  img.onload = async () => {
    const aspectRatio = img.height / img.width;
    const targetWidth = size;
    const targetHeight = targetWidth * aspectRatio;

    // 새로운 캔버스 생성
    const canvas = document.createElement("canvas");
    canvasRef.current.width = targetWidth;
    canvasRef.current.height = targetHeight;
    //display block setting
    canvasRef.current.style.display = "block";
    canvasRef.current.style.width = "30%";

    await pica().resize(img, canvasRef.current);

    canvasRef.current.toBlob((blob: any) => {
      onResizedImage(blob);
    }, inputImage.type);
  };
}

export const onResizeImg = async (
  size: number,
  files: File[],
  callback: any
) => {
  if (files === null) {
    return;
  }

  console.log(files);
  const compactedFileList: any = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    console.log("file[" + i + "]", file);

    //썸네일 이미지 압축
    await resizeImageWithNoCanvas(file, size, (blob: any) => {
      const detailFile = new File([blob], file.name, {
        type: file.type,
      });
      compactedFileList.push(detailFile as File);
    });
  }

  //2초 후 수행
  //압축시간은 그리오래걸리는것같진 않아서 줄였음.
  setTimeout(() => {
    callback(compactedFileList);
  }, 2000 * files.length);
};

export async function resizeImageWithNoCanvas(
  inputImage: any,
  size: number,
  onResizedImage: any
) {
  const img = new Image();
  img.src = URL.createObjectURL(inputImage);

  img.onload = async () => {
    const aspectRatio = img.height / img.width;
    const targetWidth = size;
    const targetHeight = targetWidth * aspectRatio;

    // 새로운 캔버스 생성
    const canvas = document.createElement("canvas");
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    //display block setting
    canvas.style.display = "block";
    canvas.style.width = "30%";

    await pica().resize(img, canvas);

    canvas.toBlob((blob: any) => {
      onResizedImage(blob);
    }, inputImage.type);

    // 생성한 캔버스를 body의 첫 번째 자식으로 추가
    const body = document.body;
    if (body.firstChild) {
      body.insertBefore(canvas, body.firstChild);
    } else {
      body.appendChild(canvas);
    }
  };
}

export function getThumbnailBinary(file: any) {
  var binaryData = [];
  binaryData.push(file);

  return binaryData;
}

//================================================================================================

export const ALLOW_FILE_EXTENSION = "jpg,jpeg,png";
// 허용가능한 확장자 목록!
const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 5MB

/**
 * 해당 함수의 기능은 .을 제거한 순수 파일 확장자를 return해준다.
 * @param originalFileName 업로드할 파일명
 * @returns .을 제거한 순수 파일 확장자(png, jpg 등)
 */
export const removeFileName = (originalFileName: string): string => {
  // 마지막 .의 위치를 구한다
  // 마지막 .의 위치다음이 파일 확장자를 의미한다
  const lastIndex = originalFileName.lastIndexOf(".");

  // 파일 이름에서 .이 존재하지 않는 경우이다.
  // 이경우 파일 확장자가 존재하지 않는경우(?)를 의미한다.
  if (lastIndex < 0) {
    return "";
  }

  // substring을 함수를 이용해 확장자만 잘라준다
  // lastIndex의 값은 마지막 .의 위치이기 때문에 해당 위치 다음부터 끝까지 문자열을 잘라준다.
  // 문자열을 자른 후 소문자로 변경시켜 확장자 값을 반환 해준다.
  return originalFileName.substring(lastIndex + 1).toLowerCase();
};

/**
 * 파일 확장자를 검사해주는 함수이다.
 * @param param
 * @returns true: 가능 확장자, false : 불가능 확장자
 */
export const fileExtensionValid = ({ name }: { name: string }): boolean => {
  // 파일 확장자
  const extension = removeFileName(name);

  /**
   * 허용가능한 확장자가 있는지 확인하는 부분은 indexOf를 사용해도 괜찮고,
   * 새롭게 나온 includes를 사용해도 괜찮고, 그밖의 다른 방법을 사용해도 좋다.
   * 성능과 취향의 따라 사용하면 될것같다.
   *
   * indexOf의 경우
   * 허용가능한 확장자가 있을경우
   * ALLOW_FILE_EXTENSION 상수의 해당 확장자 첫 index 위치값을 반환
   */
  if (!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === "") {
    // 해당 if문이 수행되는 조건은
    // 1. 허용하지 않은 확장자일경우
    // 2. 확장자가 없는경우이다.
    return false;
  }
  return true;
};

export const getFileExtension = ({ name }: { name: string }): string => {
  // 파일 확장자
  const extension = removeFileName(name);
  return extension;
};
// utils/colorUtils.ts
function hexToRgb(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number) {
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
}

function rgbToHsl(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
}

function hslToRgb(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  function hueToRgb(p: number, q: number, t: number) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h / 360);
    g = hueToRgb(p, q, h / 360 + 1 / 3);
    b = hueToRgb(p, q, h / 360 - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

export function adjustLightness(hex: string, percentage: number) {
  const { r, g, b } = hexToRgb(hex);
  let { h, s, l } = rgbToHsl(r, g, b);
  l = Math.max(0, Math.min(100, l * (1 - percentage / 100))); // 명도를 %만큼 낮춤
  const { r: newR, g: newG, b: newB } = hslToRgb(h, s, l);
  return rgbToHex(newR, newG, newB);
}
