import { addDot, getDateDifference, getPrice } from "@/Utils/Utils";
import { ProductListPropsType } from "@/types/ProductTypes";
import { ImageListItem } from "@mui/material";
import React, { Component } from "react";
import { replaceSpacesAndSpecialCharacters } from "@/Utils/Utils";

class ProductListHorizontalNode extends Component<
  ProductListPropsType & { i?: number }
> {
  render() {
    const {
      id,
      name,
      animeId,
      animeName,
      isSoldOut,
      thumbnailImgUrl,
      compactThumbnailImgUrl,
      isSales,

      detailImgUrlList,
      releaseDate,
      deadlineDate,
      arrivalDate,
      originalPrice,
      reservationPrice,
      arrivalPrice,
      isWish,
      isOptionable,
      status,
      isTradingProduct,
      createTime,
      onClick,
      i,
    } = this.props;

    const dateLeft = deadlineDate
      ? getDateDifference(new Date(deadlineDate.toString()), new Date())
      : 0;

    return (
      <ImageListItem
        style={{
          display: "inline-block",
        }}
        onClick={(e) => {
          location.href =
            `/product/detail/${id}/` +
            replaceSpacesAndSpecialCharacters(animeName + "-" + name);
          onClick();
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            margin: "0px",
          }}
        >
          <div
            style={{
              display: "inline-block",
            }}
          >
            {(this.props.i === 0 ||
              this.props.i === 1 ||
              this.props.i === 2) && (
              <img
                src={
                  i === 0
                    ? "/res/1st.png"
                    : i === 1
                    ? "/res/2nd.png"
                    : "/res/3rd.png"
                }
                style={{
                  width: "7vw",
                  height: "auto",
                  position: "absolute",
                  zIndex: 1,
                  left: "-4px",
                  top: "-6px",
                }}
              />
            )}
          </div>
          <div
            style={{
              //가로 스크롤
              display: "inline-block",
              width: "21vw",
              margin: "2px",
              padding: "2px",
              fontSize: "0.7rem",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              backgroundColor: "#ffffff",
              border: "2px solid #d6d6d6",
            }}
          >
            <img
              src={compactThumbnailImgUrl}
              style={{
                width: "100%",
                height: "21vw",
                // height: "35vw",
                objectFit: "contain",
                display: "block",
              }}
            />
            <span
              style={{
                lineHeight: "1.2",
              }}
            >
              《{animeName}》<br />
              {name}
              <br />
            </span>
            <div
              style={{
                color: "blue",
              }}
            >
              {"₩ " + addDot(status === 1 ? reservationPrice : arrivalPrice)}
              <br></br>
              <b>
                {status === 1 &&
                  deadlineDate !== undefined &&
                  dateLeft >= 0 &&
                  dateLeft <= 7 && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {dateLeft == 0 ? "오늘마감" : "마감 D-" + dateLeft + "일"}
                    </span>
                  )}
              </b>
            </div>
          </div>
        </div>
      </ImageListItem>
    );
  }
}

export default ProductListHorizontalNode;
