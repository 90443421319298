import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
import Box from "../atoms/CustomBox";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { AppBar, ButtonGroup, Toolbar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import {
  isAvailableStock,
  isAvailableReservation,
  checkAllCart,
  isOrderPossible,
  doCopy,
} from "@/Utils/Utils";
import CancelIcon from "@mui/icons-material/Cancel";
import NormalBox from "./NormalBox";
import {
  addDot,
  createFormData,
  getDateDifference,
  getDayString,
  getPrice,
  isLoginUser,
  replaceSpacesAndSpecialCharacters,
} from "@/Utils/Utils";
import getDay from "date-fns/getDay";
import { getDate } from "date-fns";
import Constants from "@/Utils/Constants";
import {
  greyBoxBorderColor,
  greyBoxColor,
  lightYellow,
  orangePrimary,
  yellowHighlightBg,
} from "@/styles/theme";
import { PropsFromRedux, connector } from "@/redux/module/product/ProductProps";
import {
  createCart,
  fetchProduct,
  fetchSelectedProduct,
} from "./../../redux/module/product/productAction";
import { get, post } from "@/redux/module/api";
import { primary, primaryText } from "@/styles/theme";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { DELIVERY_FREE_PRICE } from "./../../Utils/Constants";
import OrderButton from "../atoms/OrderButton";
import Label from "./../atoms/Label";
import PreorderWarning from "../atoms/PreorderWarning";
import { on } from "events";
import Link from "next/link";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StockSelectBtn from "../atoms/StockSelectBtn";
import { Console } from "console";
import { AxiosError } from "axios";

import coco from "@/res/drawable/character/coco3.png";
import { motion } from "framer-motion";

//이걸 설정하면 영역만큼 액션이 막힌다.
const drawerBleeding = 0;

const heightSize = "370px";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;

  isProductListCall: boolean;
  isSoldOut: number;

  onCartToggleDrawer?: any;

  id: number;
  status: number;

  isWish: number;

  isSales: number;

  isArrived: boolean;
  isTaxFree: number;

  reservationPrice: number;
  arrivalPrice: number;

  maximumOrderStock: number;
  minimumStock?: number;
  cusStock?: number;
  setItemStock?: number;

  name: string;
  title: string;
  compactThumbnailImgUrl: string;

  releaseDate?: string;
  deadlineDate?: string;

  deliveryFee?: number;

  arrivalDate?: string;
  productOptionId?: number;
  wishCount?: number;

  isNotified?: number;
}

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  // height: "70px",
  // paddingBottom: "50px",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

// const StyledBox = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
// }));

type CombinedProps = PropsFromRedux & Props;

function ProductDetailDrawer(props: CombinedProps) {
  const { window, isWish, isNotified } = props;
  const { cartResData } = props;

  const [notifyBtn, setNotifyBtn] = React.useState(
    isNotified == undefined ? false : isNotified == 1 ? true : false
  );

  const [open, setOpen] = React.useState(
    props.isProductListCall === true ? true : false
  );
  const [stock, setStock] = React.useState(1);
  const [isLogin, setIsLogin] = React.useState(false);

  const [wish, setWish] = React.useState(isWish == 1 ? true : false);
  const [cartBtnClicked, setCartBtnClicked] = React.useState(false);

  const [typedText, setTypedText] = React.useState(""); // 현재 출력 중인 텍스트 상태
  const fullText = `고객님, 구매하실 상품의 수량을 선택해주세요💜
  
  예약 상품은, 합배송과 마감일 이후 취소가 불가하답니다.
  신중하게 구매해주세요~!✨✨`
    .split("\n") // 줄 단위로 나누기
    .map((line) => line.trim()) // 각 줄의 앞뒤 공백 제거
    .join("\n"); // 다시 줄바꿈으로 합치기

  // 전체 텍스트
  const typingSpeed = 100; // 타이핑 속도 (밀리초)

  React.useEffect(() => {
    if (props.isProductListCall && props.isProductListCall === true) {
      // console.log("isProductListCall");
      props.fetchSelectedProduct(props.id, {});
      // setOpen(true);
    }

    isLoginUser().then((res: any) => {
      setIsLogin(res);
    });

    //============ typing effect ============
    let currentIndex = 0;

    const typingInterval = setInterval(() => {
      if (currentIndex < fullText.length) {
        setTypedText(fullText.slice(0, currentIndex + 1)); // 현재 인덱스까지의 텍스트 설정
        currentIndex++;
      } else {
        clearInterval(typingInterval); // 모든 텍스트가 출력되면 타이머 정지
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval); // 컴포넌트 언마운트 시 타이머 정리
  }, [fullText]); // fullText가 변경될 경우에도 동작

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  // 클라이언트 측 코드
  //  const sendMessage = async () => {
  //   const response = await post("/api/chat", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ test: "hihi" }),
  //   });

  //   const data = await response.json();
  //   console.log(data.result);
  // }

  const handleStock = (type: string) => {
    if (type === "plus") {
      const { maximumOrderStock } =
        props.isProductListCall && selectedProduct ? selectedProduct : props;

      if (
        maximumOrderStock !== undefined &&
        maximumOrderStock > 0 &&
        maximumOrderStock < stock + 1
      ) {
        alert("주문 최대 수량을 초과하였습니다.");
      } else {
        setStock(stock + 1);
      }
    } else {
      if (stock > 1) {
        setStock(stock - 1);
      }
    }
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { selectedProduct } = props;

  const { wishCount } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;

  const { maximumOrderStock } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;

  const { isSales } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;

  const { isSoldOut } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;

  const { isTaxFree } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;

  const { id } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;
  const { status } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;
  const { reservationPrice } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;
  const { arrivalPrice } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;
  // const { arrivalDate } = selectedProduct
  //   ? selectedProduct.arrivalDate
  //   : props.arrivalDate;
  // const { releaseDate } = selectedProduct
  //   ? selectedProduct.releaseDate
  // : props.releaseDate;
  const { name } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;
  const title =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct.animeName
      : props.title;
  const { compactThumbnailImgUrl } =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct
      : props;

  const arrivalDate =
    props.isProductListCall === true && selectedProduct
      ? new Date(selectedProduct.arrivalDate)
      : props.arrivalDate
      ? new Date(props.arrivalDate)
      : null;

  const deadlineDate =
    props.isProductListCall === true && selectedProduct
      ? new Date(selectedProduct.deadlineDate)
      : props.deadlineDate
      ? new Date(props.deadlineDate)
      : null;

  const releaseDate =
    props.isProductListCall === true && selectedProduct
      ? new Date(selectedProduct.releaseDate)
      : props.releaseDate
      ? new Date(props.releaseDate)
      : null;

  const setItemStock =
    props.isProductListCall === true && selectedProduct
      ? selectedProduct.setTotalStock
      : props.setItemStock;

  const price = getPrice(status, arrivalPrice, reservationPrice) || 0;

  return (
    <div
      style={{
        display: "block",
        // paddingBottom: "100px",
        paddingBottom: "10px",
      }}
    >
      {props.isProductListCall === false && (
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 0, backgroundColor: "#ffffff" }}
        >
          <Toolbar
            style={{
              marginLeft: "3px",
              marginRight: "3px",
              paddingLeft: "3px",
              paddingRight: "3px",
            }}
          >
            <Box
              width="100%"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "auto",
                  margin: 0,
                  padding: "5px",
                }}
              >
                <IconButton
                  aria-label="add to favorites"
                  style={{
                    display: "block",
                  }}
                  onClick={async (e) => {
                    if (!isLogin) {
                      alert("로그인이 필요합니다.");
                      location.href = "/login";
                      return;
                    }
                    const res = await get(
                      "product/wish/" + id + "/" + (wish ? 0 : 1),
                      {}
                    );
                    console.log(res);

                    if (res.status === 200) {
                      // alert("위시리스트 등록완료");
                      setWish(!wish);
                    }
                  }}
                >
                  {wish && (
                    <FavoriteIcon
                      style={{
                        color: orangePrimary,
                      }}
                    />
                  )}
                  {!wish && (
                    <FavoriteBorderIcon
                      style={{
                        color: orangePrimary,
                      }}
                    />
                  )}
                  {wishCount > 10 && (
                    <div
                      style={{
                        fontSize: "0.6rem",
                        color: primaryText,
                        paddingTop: "0",
                      }}
                    >
                      {wishCount}
                    </div>
                  )}
                </IconButton>
                <IconButton
                  aria-label="share"
                  onClick={() =>
                    doCopy(
                      process.env.NEXT_PUBLIC_HOST_URL + "/product/detail/" + id
                    )
                  }
                >
                  <ShareIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: 0,
                  padding: 0,
                }}
              >
                {isSales !== 2 &&
                ((status === 2 && isAvailableStock(isSoldOut, isSales)) ||
                  (status === 1 &&
                    props.deadlineDate !== undefined &&
                    isAvailableReservation(
                      props.deadlineDate,
                      isSoldOut,
                      isSales
                    ))) ? (
                  <Button
                    onClick={toggleDrawer(true)}
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundImage:
                        status === 1
                          ? "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
                          : // ? "linear-gradient(45deg,#8259a8 30%,#ffe866 110%)"
                            "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                    }}
                  >
                    <span
                      style={{
                        // fontSize: "4",
                        color: "#ffffff",
                      }}
                    >
                      {status === 1 ? "예 약 하 기" : "구 매 하 기"}
                    </span>
                  </Button>
                ) : (
                  <Button
                    onClick={async (e) => {
                      try {
                        const res = await get("member/0", {});
                        let phone = "";
                        if (res.status === 200) {
                          phone = res.data.resData.phone;

                          if (
                            phone === "" ||
                            phone === undefined ||
                            phone === null
                          ) {
                            alert(
                              "마이페이지에서 연락처 등록 후 신청이 가능합니다."
                            );
                            location.href = "/mypage";
                            return;
                          }

                          // if (
                          //   confirm(
                          //     "아래 연락처로 입고 알림 신청을 하시겠습니까?\n" +
                          //       phone
                          //   ) === true
                          // ) {
                          try {
                            let url = "";

                            if (notifyBtn == false) {
                              url = "noti/add/arrival/" + id;
                            } else if (notifyBtn == true) {
                              url = "noti/delete/arrival/" + id;
                            }

                            const result = await get(url, {});
                            if (result.status === 200) {
                              alert(
                                notifyBtn == false
                                  ? "알림 신청이 완료되었습니다."
                                  : "알림 신청이 취소되었습니다."
                              );
                              setNotifyBtn(!notifyBtn);
                              location.reload();
                            }
                          } catch (e: any) {
                            alert("Error. 알림 신청에 실패했습니다.");
                          }
                          return;
                          // }
                        } else {
                          alert("로그인이 필요합니다.");
                          location.href = "/login";
                          return;
                        }
                      } catch (e: any) {
                        if (
                          e.response.status === 401 ||
                          e.response.status === 400
                        ) {
                          alert("로그인이 필요합니다.");
                          location.href = "/login";
                          return;
                        } else {
                          alert("Internal server error");
                          location.reload();
                          return;
                        }
                      }
                    }}
                    className={notifyBtn == true ? "commonOutlinedBtn" : ""}
                    // variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      fontSize: "0.9rem",
                      fontWeight: "normal",
                      // backgroundImage:
                      //   "linear-gradient(45deg, " +
                      //   primaryText +
                      //   " 30%, #FF8E53 90%)",
                      // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                    }}
                    sx={{
                      backgroundColor:
                        notifyBtn == true ? "#ffffff" : orangePrimary,
                      borderColor: orangePrimary + " !important",
                      border: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center" /* 수직 중앙 정렬 */,
                        fontSize: "0.9rem",
                        color: notifyBtn == true ? orangePrimary : "#ffffff",
                      }}
                    >
                      {notifyBtn == true ? (
                        "알림 신청 취소"
                      ) : (
                        <>
                          <NotificationsIcon
                            style={{
                              color: "#ffffff",
                              width: "5vw",
                              padding: "3px",
                            }}
                          />{" "}
                          {isSales == 2 ? " 공개 알림 신청" : " 입고 알림 신청"}
                        </>
                      )}
                    </div>
                  </Button>
                  // <Button
                  //   variant="contained"
                  //   style={{
                  //     width: "100%",
                  //     height: "50px",
                  //     backgroundColor: greyBoxColor,
                  //     color: "#ffffff",
                  //     textAlign: "center",
                  //   }}
                  // >
                  //   <b>품 절</b>
                  // </Button>
                )}
              </div>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Root
        style={{
          display: "block",
          zIndex: 1,
        }}
      >
        {props.isProductListCall === true && (
          <div
            style={{
              position: "fixed",
              justifyContent: "center",
              zIndex: 2000,
              display: "block",
              width: "100%",
              // height: "100vw",
              top: "10%",
              left: "50%",
              transform: `translate(-50%, -19%)`,
              color: "#ffffff",
            }}
          >
            <b>〈 {name} 〉</b>
            <br />
            <img
              src={compactThumbnailImgUrl}
              style={{
                marginTop: "10px",
                borderRadius: "10px",
                width: "auto",
                height: "28vh",
              }}
            />
          </div>
        )}
        {props.isProductListCall === true && (
          <div
            style={{
              padding: "10px",
              position: "fixed",
              justifyContent: "center",
              zIndex: 2500,
              display: "block",
              // width: "100%",
              right: "0px",
              bottom: "360px",
              // transform: `translate(-50%, -19%)`,
              color: "#ffffff",
            }}
            onClick={props.onCartToggleDrawer(false)}
          >
            <CancelIcon
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </div>
        )}

        {/* //상품페이지에서는 캐릭터 출력 */}
        {props.isProductListCall != true && open == true && (
          <div
            style={{
              position: "fixed",
              justifyContent: "center",
              display: "block",
              width: "100%",
              // height: "100vw",
              left: "0vw",
              bottom: "360px",
              color: "#ffffff",
              zIndex: 2000,
            }}
          >
            <div>{}</div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: open ? 1 : 0 }}
              // animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }} // 페이드인 지속 시간 (초)
            >
              {" "}
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={coco.src}
                  onClick={(e) => {
                    setOpen(false);
                  }}
                  style={{
                    // marginLeft: "10vw",
                    width: "100%",
                    // overflow:"hidden"
                    // height: "28vh",
                  }}
                />
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    position: "absolute",
                    bottom: "20px", // 이미지의 bottom으로부터 20px
                    left: "5vw", // 이미지의 left로부터 40px
                    backgroundColor: "rgba(0, 0, 0, 0.0)", // 투명한 배경
                    color: "white",
                    padding: "10px",
                    borderRadius: "8px",
                    fontSize: "0.7rem",
                    maxWidth: "60vw", // 말풍선 너비 제한
                    height: "20vw",
                    lineHeight: "1.4",
                    alignItems: "center",
                    // whiteSpace: "normal", // 기본 줄바꿈 허용
                    wordWrap: "break-word", // 긴 단어도 줄바꿈
                    wordBreak: "break-word", // 단어 단위로 강제 줄바꿈
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)", // 약간의 그림자 효과
                  }}
                >
                  {typedText}
                </div>
              </div>
            </motion.div>
          </div>
        )}
        <CssBaseline />
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              // height: `calc(50% - ${drawerBleeding}px)`,
              height: heightSize,
              // height: `auto`,
              // overflow: "visible",
              //스크롤바 나오게 하기
              // overflow: "auto",
              overflow: "hidden",
            },
          }}
        />
        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          //나머지영역 블랙처리를 제거
          // BackdropProps={{ invisible: true }}
          onClose={
            props.isProductListCall
              ? props.onCartToggleDrawer(false)
              : toggleDrawer(false)
          }
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className="parentBox">
            <NormalBox
              title={<b>수량 선택</b>}
              right={
                <StockSelectBtn onClick={handleStock} stockValue={stock} />
              }
            />

            <NormalBox
              backgroundColor="#ffffff"
              //state으로 자식을 관리해야할때는 이렇게하자.
              title={
                <div>
                  <b>총 수량 {stock}개</b>
                </div>
              }
              right={
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    <b>{addDot(stock * price)}원</b>
                  </span>
                </div>
              }
            />
            {/* {arrivalDate != undefined &&
              arrivalDate.getDate() - new Date().getDate() > 0 && ( */}

            <div
              style={{
                // backgroundColor: "#ececec",
                paddingTop: "2px",
                marginTop: "5px",
                height: "100%",
              }}
            >
              <Divider
                variant="middle"
                className="divier"
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              />
              {status === 1 && <PreorderWarning />}
              {status === 1 && (
                <NormalBox
                  type="sub"
                  //state으로 자식을 관리해야할때는 이렇게하자.
                  title={
                    <div>
                      <b>출고예정일</b>
                    </div>
                  }
                  backgroundColor={yellowHighlightBg}
                  right={
                    <div>
                      <span className="normalSpanSub">
                        <b>
                          {" "}
                          {props.arrivalDate &&
                            props.arrivalDate
                              .replace("T", " ")
                              .substring(0, 10)}
                          {/* //요일 출력 */}{" "}
                          {arrivalDate && getDayString(arrivalDate)}
                          {"요일"}
                        </b>
                        {arrivalDate &&
                          getDateDifference(new Date(), arrivalDate) > 0 && (
                            <Label
                              content={
                                getDateDifference(new Date(), arrivalDate) +
                                "일 남았습니다"
                              }
                              color={orangePrimary}
                            />
                          )}
                      </span>
                    </div>
                  }
                />
              )}
              {status === 2 && (
                <NormalBox
                  type="sub"
                  //state으로 자식을 관리해야할때는 이렇게하자.
                  title={
                    <div>
                      <b>배송예정일</b>
                    </div>
                  }
                  backgroundColor={yellowHighlightBg}
                  right={
                    <div>
                      <span
                        className="normalSpanSub"
                        style={{
                          color: "blue",
                        }}
                      >
                        <b>영업일 1-3일 이내</b>
                      </span>
                    </div>
                  }
                />
              )}
              {setItemStock != undefined && setItemStock > 0 && (
                <NormalBox
                  backgroundColor={lightYellow}
                  type="sub"
                  //state으로 자식을 관리해야할때는 이렇게하자.
                  title={
                    <div>
                      <span
                        className="normalSpanSub"
                        style={{
                          color: "blue",
                        }}
                      >
                        <b>{setItemStock}개 구매 시 마다 BOX 출고</b>
                      </span>
                    </div>
                  }
                />
              )}

              {/* {arrivalDate != undefined &&
                arrivalDate.getDate() - new Date().getDate() <= 0 && ( */}

              {/* {isArrived == true && (
                <NormalBox
                  type="sub"
                  //state으로 자식을 관리해야할때는 이렇게하자.
                  title={<div>배송예정일</div>}
                  right={
                    <div>
                      <span className="normalSpanSub">
                        {"영업일 16:00 이전 구매 시 「당일 출고」"}
                      </span>
                    </div>
                  }
                />
              )} */}
              <NormalBox
                type="sub"
                //state으로 자식을 관리해야할때는 이렇게하자.
                title={
                  <div>
                    <b>배송료</b>{" "}
                    {" (" + addDot(DELIVERY_FREE_PRICE) + "원 이상 무료배송)"}
                  </div>
                }
                right={
                  <div>
                    <span className="normalSpanSub">
                      <b>
                        {stock * price < Constants.DELIVERY_FREE_PRICE
                          ? "+" + addDot(Constants.DELIVERY_FEE) + "원"
                          : "무료"}
                      </b>
                    </span>
                  </div>
                }
              />
            </div>
            <AppBar
              style={{
                display: "block",
              }}
              position="fixed"
              color="primary"
              sx={{ top: "auto", bottom: 0, backgroundColor: "#ffffff" }}
            >
              <Toolbar>
                <Box
                  width="100%"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {status === 2 && (
                    <Button
                      variant="outlined"
                      disabled={cartBtnClicked}
                      onClick={async (e) => {
                        if (!isLogin) {
                          alert("로그인이 필요합니다.");
                          location.href = "/login";
                        } else {
                          setCartBtnClicked(true);
                          const result = await isOrderPossible(props.id, stock);

                          if (result === false) return;

                          try {
                            const response = await post(
                              "cart",
                              createFormData({
                                productId: props.id + "",
                                quantity: stock + "",
                                productOptionId: props.productOptionId
                                  ? props.productOptionId + ""
                                  : 0 + "",
                              }),
                              {}
                            );

                            if (response.status == 200) {
                              //확인 버튼 누르면 장바구니로 이동

                              if (
                                confirm(
                                  "장바구니에 담겼습니다.\n장바구니로 이동하시겠습니까?"
                                ) === true
                              ) {
                                location.href = "/cart";
                              }
                            } else if (response.status === 201) {
                              //CREATED
                              alert(
                                "이미 장바구니에 담긴 상품입니다.\n장바구니로 이동하여 수량을 변경해주세요."
                              );
                              location.href = "/cart";
                            }
                          } catch (e: any) {
                            if (e.response.status == 400) {
                              console.log(e);
                              if (
                                e.response.data === "NO_PRODUCT" ||
                                e.response.data === "INVISIBLE"
                              ) {
                                alert("잘못 된 요청입니다.");
                                location.href = "/";
                                return;
                              } else if (e.response.data === "NO_STOCK") {
                                alert("상품이 품절 되었습니다.");
                                location.reload();
                                return;
                              } else if (
                                e.response.data ===
                                "OVER_MAXIMUM_ORDER_QUANTITY"
                              ) {
                                alert("주문 최대 수량을 초과했습니다.");
                                location.reload();
                                return;
                              } else if (
                                e.response.data === "OVER_MAX_QUANTITY"
                              ) {
                                alert(
                                  "주문 수량이 상품의 재고 수량보다 많습니다."
                                );
                                // location.reload();
                                return;
                              } else {
                                alert("Internal server error");
                                location.reload();
                                return;
                              }
                            } else if (e.response.status == 401) {
                              alert("로그인이 필요합니다.");
                              location.href = "/login";
                              return;
                            } else {
                              alert("Internal server error");
                              location.reload();
                              return;
                            }
                          }

                          setCartBtnClicked(false);
                        }
                      }}
                      style={{
                        width: "50%",
                        height: "50px",
                        borderColor: "#FE6B8B",
                        // backgroundImage:

                        //   "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                        // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                      }}
                    >
                      <span
                        style={{
                          // fontSize: "4",
                          // color: "#ffffff",
                          color: "#FE6B8B",
                        }}
                      >
                        장바구니
                      </span>
                    </Button>
                  )}
                  <OrderButton
                    width={status === 2 ? "45%" : "100%"}
                    style={{
                      width: status === 2 ? "45%" : "100%",
                      height: "50px",
                      marginLeft: "10px",
                    }}
                    contents={status === 2 ? "구매하기" : "예 약 하 기"}
                    amount={stock * price}
                    isCartOrder={0}
                    preorder={status === 2 ? false : true}
                    taxFreeAmount={(isTaxFree as number) === 1 ? 0 : price}
                    oneProductData={[
                      {
                        id: 0,
                        productId: id,
                        quantity: stock,
                        name: name,
                        title: title,
                        price: price,
                        compactThumbnailImgUrl: compactThumbnailImgUrl,
                        isTaxFree: isTaxFree,
                      },
                    ]}
                  />
                </Box>
              </Toolbar>
            </AppBar>
          </div>
        </SwipeableDrawer>
      </Root>
    </div>
  );
}
export default connector(ProductDetailDrawer);
