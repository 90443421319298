import { get } from "@/redux/module/api";
import { ProductListPropsType } from "@/types/ProductTypes";
import { ListPropsType } from "@/types/PropsTypes";
import React, { Component } from "react";
import ProductListHorizontalNode from "./ProductListHorizontalNode";
import { addRecentlyViewedProduct, saveScrollPosition } from "@/Utils/Utils";

class ProductListHorizontal extends Component<
  ListPropsType & {
    url: string;

    animeId1?: number;
    animeId2?: number;
    animeId3?: number;
    animeId4?: number;
    animeId5?: number;
  },
  { resData: ProductListPropsType[] }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      resData: [],
    };
  }

  onClick = (recentProduct: ProductListPropsType) => {
    addRecentlyViewedProduct(recentProduct);
    saveScrollPosition(0);
  };
  componentDidMount = async () => {
    const params =
      this.props.animeId1 !== 0
        ? {
            animeId1: this.props.animeId1,
            animeId2: this.props.animeId2,
            animeId3: this.props.animeId3,
            animeId4: this.props.animeId4,
            animeId5: this.props.animeId5,
          }
        : {};
    const res = await get(
      this.props.animeId1 !== undefined && this.props.animeId1 !== 0
        ? "product/top20"
        : this.props.url,
      params
    );

    if (res.status === 200) {
      this.setState({
        resData: res.data.resData,
      });
    }
  };
  render() {
    return this.state.resData && this.state.resData.length > 0 ? (
      <div
        className="slide-container"
        style={{
          //가로 스크롤
          margin: "3px",
          overflowX: "scroll",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          width: "100%",
          height: "auto",
        }}
      >
        <div className="slide-item">
          {this.state.resData.map(
            (data: ProductListPropsType, index: number) => {
              return (
                <ProductListHorizontalNode
                  i={index}
                  arrivalPrice={data.arrivalPrice}
                  key={index}
                  id={data.id}
                  name={data.name}
                  animeId={data.animeId}
                  animeName={data.animeName}
                  isSoldOut={data.isSoldOut}
                  thumbnailImgUrl={data.thumbnailImgUrl}
                  compactThumbnailImgUrl={data.compactThumbnailImgUrl}
                  isSales={data.isSales}
                  detailImgUrlList={data.detailImgUrlList}
                  releaseDate={data.releaseDate}
                  deadlineDate={data.deadlineDate}
                  arrivalDate={data.arrivalDate}
                  isWish={data.isWish}
                  isOptionable={data.isOptionable}
                  status={data.status}
                  isTradingProduct={data.isTradingProduct}
                  createTime={data.createTime}
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    this.onClick(data)
                  }
                  originalPrice={data.originalPrice}
                  reservationPrice={data.reservationPrice}
                />
              );
            }
          )}
        </div>
      </div>
    ) : (
      <div>상품이 없습니다.</div>
    );
  }
}

export default ProductListHorizontal;
