import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from "@mui/material";
import React, { Component } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NoticeNodePropsType } from "@/types/PropsTypes";
// import { Box } from "@mui/material/Box";
import Box from "../atoms/CustomBox";

class NoticeNode extends Component<
  {
    resData: NoticeNodePropsType;
    index: number;
  },
  { expanded: string }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      expanded: "false",
    };
  }

  handleChange = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  render() {
    return (
      <Box
        style={{
          fontSize: "0.75rem",
        }}
        sx={{
          display: "flex",
          borderRadius: "10px",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "auto",
            //padding: "15px",
            margin: "1px",
            marginLeft: "10px",
            marginRight: "10px",
            padding: "0px",
          },
        }}
      >
        <Accordion
          elevation={0}
          expanded={this.state.expanded === "panel" + this.props.index}
          onChange={this.handleChange("panel" + this.props.index)}
          style={{
            padding: "0px",
            margin: "2px",

            backgroundColor:
              this.props.resData.createTime === "" ? "#efe8f1" : "#efd9ff",
            //   height: "8vw",
            minHeight: "40px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              //   padding: "4px",
              margin: "0px",
            }}
          >
            {this.props.resData.label !== "" && (
              <Chip
                label={this.props.resData.label}
                color={
                  this.props.resData.createTime === "" ? "default" : "secondary"
                }
                style={{
                  width: "25%",
                  fontSize: "0.75rem",
                  float: "left",
                  height: "16px",
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                marginLeft: "7px",
                width: "50%",
                fontSize: "0.75rem",
              }}
            >
              {this.props.resData.createTime === ""
                ? this.props.resData.title
                : this.props.resData.title}
            </div>

            <div
              style={{
                display: "inline-block",

                width: "25%",
                textAlign: "right",
                float: "right",
                fontSize: "0.6rem",
              }}
            >
              {this.props.resData.createTime &&
                this.props.resData.createTime.replace("T", " ").substr(0, 10)}
            </div>
          </AccordionSummary>

          <AccordionDetails>
            {this.props.resData.createTime &&
              "등록일 : " + this.props.resData.createTime.replace("T", " ")}
            <p></p>
            <div
              style={{
                textAlign: "left",
                whiteSpace: "pre-line",
                fontSize: "0.8rem",
                margin: "10px",
              }}
            >
              {this.props.resData.content}
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}

export default NoticeNode;
