import React, { Component } from "react";

import Utils, { handleDetailRouter } from "@/Utils/Utils";
import { isAvailableStock, isAvailableReservation } from "@/Utils/Utils";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { ProductListPropsType } from "@/types/ProductTypes";
import {
  addDot,
  getDateDifference,
  getPrice,
  getRGBAValue,
  isLoginUser,
  replaceSpacesAndSpecialCharacters,
} from "@/Utils/Utils";
import {
  success,
  secondary,
  greyBoxColor,
  primaryText,
  lightGrey,
  lightYellow,
  orangePrimary,
  primary,
  primaryProductNode,
} from "@/styles/theme";
import Link from "next/link";
import Label from "../atoms/Label";
import BoxIcon from "../atoms/BoxIcon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ProductDetailImageSlider from "./ProductDetailImageSlider";
import { get } from "@/redux/module/api";
import { useRouter } from "next/router";

type CombinedProps = ProductListPropsType & {
  lineSize: number;
  onCartClick?: any;
  key?: any;
};

export default function ProductListNode(props: CombinedProps) {
  const {
    id,
    name,
    animeId,
    animeName,
    isSoldOut,
    thumbnailImgUrl,
    compactThumbnailImgUrl,
    isSales,

    detailImgUrlList,
    releaseDate,
    deadlineDate,
    arrivalDate,
    originalPrice,
    reservationPrice,
    arrivalPrice,
    isWish,
    isOptionable,
    status,
    lineSize,
    isTradingProduct,
    onCartClick,
    key,
    createTime,
    onClick,
  } = props;

  // const LINE_SIZE: number = 2;
  const LINE_PERCENT = 100 / lineSize + "%";

  const [wish, setWish] = React.useState(isWish === 1 ? true : false);
  const [isLiked, setIsLiked] = React.useState(false);

  const [isLogin, setIsLogin] = React.useState(false);

  const router = useRouter();

  React.useEffect(() => {
    // isLoginUser().then((res: any) => {
    //   setIsLogin(res);
    // });
  }, []);

  const soldout =
    (status === 2 && !isAvailableStock(isSoldOut, isSales)) ||
    (status === 1 &&
      !isAvailableReservation(deadlineDate + "", isSoldOut, isSales))
      ? true
      : false;

  return (
    <Card
      onClick={onClick ? onClick : null}
      key={key}
      sx={{
        width: LINE_PERCENT,
        // maxWidth: 345,
        margin: lineSize === 2 ? "5px" : "3px",
        marginBottom: "5px",

        // backgroundColor: "#f6ecff",

        backgroundColor: "#ffffff",

        // backgroundColor: "#f2e3ff",
        // backgroundImage: "linear-gradient(50deg, #b69ecb 30%, #fffd67 80%)",

        border: lineSize === 2 ? 2 : 1,
        borderColor: lineSize === 2 ? primaryProductNode : "#ffc1da",
        // borderColor: "rgba(252,252,230,0.90)",
        // borderColor: "white",
        borderRadius: lineSize === 2 ? "8px" : "3px",
        elevation: 4,
        boxShadow:
          lineSize === 2
            ? "0px 0px 6px" + //마지막인자가 퍼짐 정도
              " 1px " +
              primaryProductNode //첫번째인자가 진한 밀집?정도
            : "0px 0px 2px" + //마지막인자가 퍼짐 정도
              " 1px " +
              primaryProductNode, //첫번째인자가 진한 밀집?정도
        // " 1px #b66c9d", //첫번째인자가 진한 밀집?정도
        // " 0px #ffffff", //첫번째인자가 테두리?정도
      }}
    >
      {/* //링크이동 */}
      {/* <Link
        href={
          `/product/detail/${id}/` +
          //특수문자제거
          replaceSpacesAndSpecialCharacters(animeName + "-" + name)
        }
        style={{
          textDecoration: "none",
        }}
      > */}
      <CardActionArea>
        <div
          style={{
            display: "absolute",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              style={{
                // backgroundColor: "#fff4c3",
                backgroundColor: getRGBAValue("#fff4c3", 1),
                margin: "0px",
                padding: "3px",
                top: "0px",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "0.7rem",
              }}
            >
              <b>
                {"《 "}
                {animeName}
                {" 》"}
              </b>
            </Typography>
            <ImageListItem>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <IconButton
                  aria-label="add to favorites"
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    right: "3px",
                    bottom: "15px",
                  }}
                  onClick={async (e) => {
                    try {
                      const res = await get(
                        "product/wish/" + id + "/" + (wish ? 0 : 1),
                        {}
                      );
                      console.log(res);

                      if (res.status == 200) {
                        // alert("위시리스트 등록완료");
                        setWish(!wish);
                      }
                    } catch (e: any) {
                      if (e.response.status == 401) {
                        alert("로그인이 필요합니다.");
                        location.href = "/login";
                      } else {
                        alert("위시리스트 등록실패");
                      }
                    }
                  }}
                >
                  {wish ? (
                    <FavoriteIcon
                      style={{
                        color: orangePrimary,
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      style={{
                        color: orangePrimary,
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  )}
                </IconButton>
                {/* {((status === 2 && !isAvailableStock(isSoldOut, isSales)) ||
                  (status === 1 &&
                    !isAvailableReservation(
                      deadlineDate + "",
                      isSoldOut,
                      isSales
                    ))) && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    onClick={(e) => {
                      handleDetailRouter(
                        router,
                        `/product/detail/${id}/` +
                          replaceSpacesAndSpecialCharacters(
                            animeName + "-" + name
                          )
                      );
                    }}
                    style={{
                      position: "absolute",
                      // backgroundColor: "#fff4c3",
                      backgroundColor: getRGBAValue("#000000", 0.6),
                      margin: "3px",
                      padding: "4px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#ffffff",
                      borderRadius: "4px",
                      // borderRadius: "0 0 4px 0",
                      // //제일위쪽에 배치
                      zIndex: 2,

                      fontSize: "0.8rem",
                      //좌측 상단에 배치
                      // left: "5px",
                      // top: "20px",
                      //좌측하단에 배치
                      // bottom: "20px",
                    }}
                  >
                    <span>{"SOLD OUT"}</span>
                  </Typography>
                )} */}
                {status === 1 && isSales !== 2 && (
                  <span>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        position: "absolute",
                        // backgroundColor: "#fff4c3",
                        backgroundColor:
                          // 1: 예약 , 2 : 재고
                          status === 1
                            ? getRGBAValue("#782eff", 1)
                            : getRGBAValue("#ff8b0f", 1),
                        margin: "0px",
                        padding: "4px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#ffffff",
                        // borderRadius: "0px 0px 4px 0px",
                        borderRadius: "0px 0px 0px 4px",
                        // //제일위쪽에 배치
                        zIndex: 1,

                        right: "0px",
                        top: "0px",

                        //좌측 상단에 배치
                        // left: "0px",
                        // top: "0px",

                        fontSize: "0.6rem",
                        //좌측하단에 배치
                        // bottom: "0px",
                      }}
                    >
                      {status === 1 ? "예약" : "바로배송"}
                    </Typography>
                    {deadlineDate && isSales !== 2 && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{
                          position: "absolute",
                          // backgroundColor: "#fff4c3",
                          backgroundColor:
                            getDateDifference(
                              new Date(deadlineDate.toString()),
                              new Date()
                            ) > 0
                              ? orangePrimary
                              : // : "#ff3636",
                                "#9f4949",
                          margin: "0px",
                          padding: "4px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "#ffffff",
                          // borderRadius: "0px 0px 4px 0px",
                          borderRadius: "0px 0px 0px 4px",
                          // //제일위쪽에 배치
                          zIndex: 1,

                          right: "5.6vw",
                          top: "0px",

                          //좌측 상단에 배치
                          // left: "0px",
                          // top: "0px",

                          fontSize: "0.6rem",
                          //좌측하단에 배치
                          // bottom: "0px",
                        }}
                      >
                        {getDateDifference(
                          new Date(deadlineDate.toString()),
                          new Date()
                        ) === 0
                          ? "오늘마감"
                          : getDateDifference(
                              new Date(deadlineDate.toString()),
                              new Date()
                            ) > 0
                          ? "마감D-" +
                            getDateDifference(
                              new Date(deadlineDate.toString()),
                              new Date()
                            )
                          : "예약종료"}
                      </Typography>
                    )}
                  </span>
                )}
                {isTradingProduct === 1 ? (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      position: "absolute",
                      margin: "0px",
                      padding: "4px",
                      paddingTop: "3px",
                      paddingLeft: "0px",
                      paddingBottom: "2px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "white",
                      // //제일위쪽에 배치
                      zIndex: 1,

                      fontSize: "0.8rem",

                      //좌측상단에 배치
                      left: "0px",
                      bottom: "18px",
                      // bottom: "3vw",
                    }}
                  >
                    <Label
                      content={
                        <>
                          <span
                            style={{ color: primaryText, fontSize: "0.7rem" }}
                          >
                            <b>랜덤</b>
                          </span>
                        </>
                      }
                      color={"#ffffff"}
                      border={2}
                      borderColor={primaryText}
                    />
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      position: "absolute",
                      margin: "0px",
                      padding: "4px",
                      paddingTop: "3px",
                      paddingLeft: "0px",
                      paddingBottom: "2px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "white",
                      // //제일위쪽에 배치
                      zIndex: 1,

                      fontSize: "0.8rem",

                      //좌측상단에 배치
                      left: "0px",
                      bottom: "18px",
                      // bottom: "3vw",
                    }}
                  >
                    <Label
                      content={
                        <>
                          <span
                            style={{ color: "#ffffff", fontSize: "0.7rem" }}
                          >
                            확정
                          </span>
                        </>
                      }
                      color={primaryText}
                      border={1.5}
                      borderColor={"#7d00b7"}
                    />
                  </Typography>
                )}
                {/* //================= 공개 예정 상품 ============== */}
                {isSales === 2 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      position: "absolute",
                      margin: "0px",
                      padding: "4px",
                      paddingTop: "3px",
                      paddingLeft: "0px",
                      paddingBottom: "2px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "white",
                      // //제일위쪽에 배치
                      zIndex: 1,

                      fontSize: "0.8rem",

                      //좌측상단에 배치
                      left: "0px",
                      top: "0px",
                    }}
                  >
                    <Label
                      content={
                        <>
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: "0.9rem",
                            }}
                          >
                            공개 예정
                          </span>
                        </>
                      }
                      color={"#ff7caa"}
                      border={1.5}
                      borderColor={"#ff3a80"}
                    />
                  </Typography>
                )}
                {releaseDate && status === 1 && lineSize === 2 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      position: "absolute",
                      margin: "0px",
                      padding: "4px",
                      paddingTop: "3px",
                      paddingLeft: "0px",
                      paddingBottom: "2px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "white",
                      // //제일위쪽에 배치
                      zIndex: 1,

                      fontSize: "0.8rem",

                      //좌측상단에 배치
                      left: "0px",
                      top: "0px",
                    }}
                  >
                    <Label
                      content={
                        <>
                          <span
                            style={{
                              color: orangePrimary,
                              fontSize: "0.7rem",
                            }}
                          >
                            <b>
                              {"'"}
                              {releaseDate.toString().substring(2, 4)}
                              {". "}
                              {releaseDate.toString().substring(5, 7)}月{"발매"}
                            </b>
                          </span>
                        </>
                      }
                      color={"#ffffff"}
                      border={1.5}
                      borderColor={orangePrimary}
                    />
                  </Typography>
                )}
                {/* <CardMedia
            component="img"
            //height에 맞춰서 width가 자동으로 조절됨
            object-fit="contain"
            height="200px"
            image={compactThumbnailImgUrl}
            //   alt="green iguana"
          /> */}

                {detailImgUrlList ? (
                  <ProductDetailImageSlider
                    productImageList={detailImgUrlList}
                    isDetailPage={false}
                    isSoldOut={isSoldOut}
                    status={status}
                    link={
                      `/product/detail/${id}/` +
                      replaceSpacesAndSpecialCharacters(animeName + "-" + name)
                    }
                  />
                ) : (
                  <img
                    src={compactThumbnailImgUrl}
                    onClick={(e) => {
                      handleDetailRouter(
                        router,
                        `/product/detail/${id}/` +
                          replaceSpacesAndSpecialCharacters(
                            animeName + "-" + name
                          )
                      );
                    }}
                    style={{
                      filter:
                        (status === 2 &&
                          isAvailableStock(
                            isSoldOut as number,
                            isSales as number
                          ) === false) ||
                        (status === 1 &&
                          deadlineDate &&
                          isAvailableReservation(
                            deadlineDate + "",
                            isSoldOut as number,
                            isSales as number
                          ) === false)
                          ? "grayscale(100%)"
                          : "none",
                      // filter: isSoldOut === 1 && "brightness(70%)",

                      //height에 맞춘 width.
                      //width가 벗어날경우 본 창에 맞춘다.
                      objectFit: "contain",
                      // objectFit: "cover",
                      // objectFit: "cover",
                      //자르는걸 원하면 cover처리

                      width: "100%",
                      height: "180px",
                    }}
                  />
                )}
              </div>
            </ImageListItem>
          </div>

          <ImageListItemBar
            onClick={(e) => {
              handleDetailRouter(
                router,
                `/product/detail/${id}/` +
                  replaceSpacesAndSpecialCharacters(animeName + "-" + name)
              );
            }}
            sx={{
              background:
                "linear-gradient(to top, rgba(255,255,255,1) 0%, " +
                "rgba(255,255,255,0.8) 55%, rgba(255,255,255,0) 100%)",
              height: "22%",
              margin: "0",
              padding: "0",
            }}
            style={{
              padding: "0",
            }}
            position="bottom"
            title={
              <div
                style={{
                  display: "inline-block",

                  margin: "0",
                  padding: "0",
                  paddingTop: "11%",
                  //아래쪽에 정렬
                  justifyContent: "flex-end",
                  alignItems: "flex-end", //아래쪽에 정렬
                  width: "100%",
                  height: "100%",
                }}
                onClick={(e) => {
                  handleDetailRouter(
                    router,
                    `/product/detail/${id}/` +
                      replaceSpacesAndSpecialCharacters(animeName + "-" + name)
                  );
                }}
              >
                {" "}
                <div
                  style={{
                    //왼쪽정렬

                    height: "20px",
                    margin: "0",
                    padding: "0",
                    display: "inline-block",
                    // maxWidth: "120%",
                    //글자 줄임
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    verticalAlign: "bottom",
                    width: "100%",

                    fontSize: "0.7rem",
                    color: "#000000",
                    // textShadow: "0px 0px 13px white",
                  }}
                >
                  {name}
                </div>
                {/* <div
                    style={{
                      margin: "0",
                      padding: "0",
                      // paddingLeft: "3px",
                      //오른쪽 정렬
                      display: "inline-block",
                      justifyContent: "flex-end",
                      float: "right",
                      textAlign: "right",
                      alignItems: "right",
                      maxWidth: "30%",
                      fontSize: "0.7rem",
                      whiteSpace: "nowrap",
                      color: "#8700ff",
                    }}
                  >
                    <b>
                      {"₩"}
                      {addDot(reservationPrice ? reservationPrice : 0)}
                    </b>
                  </div> */}
              </div>
            }

            // subtitle={
            //   <div
            //     style={{
            //       margin: 0,
            //       padding: 0,
            //       width: 0,
            //       height: 0,
            //     }}
            //   ></div>
            // }
          />
        </div>
      </CardActionArea>
      {/* </Link> */}

      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            // padding: "5px",
            paddingLeft: "0",
            paddingTop: "5px",
            textAlign: "left",
            display: "inline-block",
            overflow: "hidden",
            width: "55%",
          }}
        >
          <div
            style={{
              display: "inline-block",
              backgroundColor: "#f5ebff",
              color: soldout ? "#555555" : "#8700ff",

              margin: "0px",
              width: "90%",
              padding: "4px",
              paddingRight: "7px",
              paddingTop: "1px",
              paddingBottom: "2px",
              whiteSpace: "nowrap",
              float: "left",
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // borderRadius: "10px 0px 0px 10px",
              borderRadius: "0px 10px 10px 0px",

              fontSize: lineSize === 2 ? "0.8rem" : "0.7rem",
            }}
          >
            <b>
              {soldout ? (
                " ⓧ Sold out"
              ) : (
                <>
                  {"₩ "}
                  {addDot(
                    getPrice(
                      status,
                      arrivalPrice || 0,
                      reservationPrice || 0
                    ) || 0
                  )}
                </>
              )}
            </b>
          </div>
        </div>
        <div
          style={{
            margin: "3px",
            // padding: "5px",
            textAlign: "right",
            justifyContent: "center !important",
            display: "inline-block",
            width: "45%",
          }}
        >
          {/* <BoxIcon
            color={greyBoxColor}
            icon={
              <ThumbUpOffAltIcon
                style={{
                  width: "15px",
                  height: "15px",
                }}
              />
            }
          /> */}
          {isSales !== 2 &&
            ((status === 2 && isAvailableStock(isSoldOut, isSales)) ||
              (status === 1 &&
                deadlineDate &&
                isAvailableReservation(
                  deadlineDate + "",
                  isSoldOut,
                  isSales
                ))) && (
              <BoxIcon
                onClick={(e: any) => onCartClick(id)}
                width={lineSize === 2 ? "6vw" : "4vw"}
                color={primaryText}
                icon={
                  <ShoppingCartIcon
                    style={{
                      width: lineSize === 2 ? "19px" : "15px",
                      height: lineSize === 2 ? "19px" : "15px",
                    }}
                  />
                }
              />
            )}
          <BoxIcon
            color={primaryText}
            width={lineSize === 2 ? "5vw" : "3vw"}
            onClick={() => {
              alert("[좋아요] 기능은 현재 준비중입니다.");
            }}
            icon={
              <ThumbUpOffAltIcon
                style={{
                  color: primaryText,
                  width: lineSize === 2 ? "18px" : "14px",
                  height: lineSize === 2 ? "18px" : "14px",
                }}
              />
            }
            unchecked={true}
          />
        </div>
      </div>
    </Card>
  );
}
