import {
  Box,
  Card,
  CardActionArea,
  ImageListItemBar,
  Paper,
} from "@mui/material";
import React, { Component } from "react";
import GradientImage from "../atoms/GradientImage";
import { primaryProductNode } from "@/styles/theme";
import { type } from "os";

export interface PromotionProps {
  id: number;
  link: string;
  color: string;
  title: string;
  subTitle: string;
  thumbnailImgUrl: string;
  thumbnailImg?: any;
  type?: string;
}

class Promotion extends Component<PromotionProps> {
  constructor(props: PromotionProps) {
    super(props);
  }

  render() {
    return (
      <a
        href={this.props.link}
        style={{
          textDecoration: "none",
        }}
      >
        <Card
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            height: "55vw",
            // height: "85vw",
            borderRadius: 0,
          }}
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            "& > :not(style)": {
              // m: 1,
              padding: 0,
              margin: 0,
              width: this.props.type === "product" ? "120%" : "100%",
              // transform:
              //   this.props.type === "product"
              //     ? `translate(-10%, -10%)`
              //     : "" /* 중심에서 확장 */,

              // transform:
              // `scale(${
              //   this.props.type === "product" ? 1.3 : 1
              // })
              // `translate(${
              //   this.props.type === "product" ? "-10%, -10%" : "0, 0"
              // })` /* 중심에서 확장 */,

              backgroundImage: `url(${this.props.thumbnailImgUrl})`,
              //   backgroundSize: "contain",
              backgroundSize:
                this.props.type === "product" ? "contain" : "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              overflow: "hidden",
            },
          }}
        >
          <CardActionArea>
            <ImageListItemBar
              style={{
                padding: 0,
              }}
              sx={{
                background:
                  "linear-gradient(to top, rgba(0,0,0,0.6) 0%, " +
                  "rgba(0,0,0,0) 100%)",
                height: "30%",
                margin: 0,
                padding: 0,
              }}
              position="bottom"
              title={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "left",
                    alignItems: "center",
                    padding: "10px",
                    paddingTop: "15px",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: "0px",
                      display: "block !important",
                      //줄바꿈되도록하기
                      //   whiteSpace: "pre-wrap",
                      //   whiteSpace: "nowrap",
                      flex: 1,
                      color: "#f8ff82",
                      fontSize:
                        this.props.type === "product" ? "0.8rem" : "1.15rem",
                      // marginLeft:
                      //   this.props.type === "product" ? "90px" : "0px",
                      // textAlign:
                      //   this.props.type === "product" ? "center" : "left",

                      whiteSpace: "pre-wrap",
                      textShadow: "0px 0px 13px black",
                      fontFamily: "headline1",
                    }}
                  >
                    {this.props.title}
                    {/* </font> */}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      //줄바꿈되도록하기
                      whiteSpace: "pre-wrap",
                      flex: 1,
                      color: "white",
                      fontSize: "0.8rem",
                      paddingBottom: 0,
                      fontFamily: "normal_kirakira_bold",

                      textShadow: "0px 0px 13px black",
                    }}
                  >
                    {this.props.subTitle}
                  </div>
                </div>
              }
              // subtitle={kujiPrizeAlphabet + "상 : " + kujiPrizeName}
              // actionIcon={
              //   <IconButton
              //     sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              //     aria-label={`info about ${kujiNode.kujiPrizeName}`}
              //   >
              //     <InfoIcon />
              //   </IconButton>
              // }
            />
          </CardActionArea>
        </Card>
      </a>
    );
  }
}

export default Promotion;
