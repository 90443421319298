import { get } from "@/redux/module/api";
import { CategoryNodePropsType, ProductDetailImg } from "@/types/ProductTypes";
import React, { Component } from "react";
import MainCategoryListNode from "./MainCategoryListNode";

import NoImg from "../../res/drawable/no-image.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay, Scrollbar, EffectFade } from "swiper";
import { SwiperProps } from "swiper/react";

import SwiperCore from "swiper";
import "swiper/css"; // Swiper의 CSS 파일을 import
import "swiper/css/navigation"; // Navigation 모듈의 CSS 파일을 import
import "swiper/css/pagination"; // Pagination 모듈의 CSS 파일을 import
import "swiper/css/effect-coverflow"; // Coverflow 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-fade"; // Fade 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-flip"; // Flip 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-cube"; // Cube 효과 모듈의 CSS 파일을 import
import dynamic from "next/dynamic";
import { NextRouter, withRouter } from "next/router";
import { CardMedia } from "@mui/material";
import detail from "./../../pages/product/detail/[id]";
import Link from "next/link";
import { getRandomNumberOrUndefined } from "@/Utils/Utils";
import { type } from "os";

const DynamicSwiper = dynamic<SwiperProps>(
  () => import("swiper/react").then((module) => module.Swiper),
  { ssr: false }
);

interface Props {
  router: NextRouter;
  productImageList: ProductDetailImg[];

  type?: string;
  isDetailPage?: boolean;
  isSoldOut?: number;
  status?: number;
  link?: any;
}

class ProductDetailImageSlider extends Component<Props> {
  constructor(props: any) {
    super(props);

    // SwiperCore.use([Pagination, Autoplay /*Scrollbar*/]);

    // Swiper 모듈 활성화
    // if (this.props.isDetailPage === true) {
    SwiperCore.use([Pagination, Autoplay, EffectFade]);
    // } else {
    //   SwiperCore.use([Autoplay, EffectFade]);
    // }
  }

  getImg = (imgNode: ProductDetailImg) => {
    return (
      imgNode.thumbnailImgUrl !== "" && (
        <img
          src={imgNode.thumbnailImgUrl}
          style={{
            filter:
              this.props.isSoldOut === 1 && this.props.status === 2
                ? "grayscale(100%)"
                : "none",
            // filter: isSoldOut === 1 && "brightness(70%)",

            //height에 맞춘 width.
            //width가 벗어날경우 본 창에 맞춘다.
            objectFit: "contain",
            // objectFit: "cover",

            zIndex: 0,
            width: "100%",
            height: "190px",
          }}
        />
      )
    );
  };

  componentDidMount = async () => {};
  render() {
    const { productImageList } = this.props;
    const { isDetailPage, isSoldOut, status } = this.props;

    const PAGE_SIZE = 100;
    return this.props.productImageList &&
      this.props.productImageList.length > 0 ? (
      <>
        {/* //다수 이미지인 경우만 swiper를 사용한다 */}
        {isDetailPage !== false || productImageList.length > 1 ? (
          <div className="pagination-container" style={{ width: "100%" }}>
            <Link
              href={this.props.link !== undefined ? this.props.link : "#"}
              style={{ textDecoration: "none" }}
            >
              <DynamicSwiper
                effect="fade" // 페이드 효과 적용
                fadeEffect={{
                  crossFade: true, // 페이드 효과 부드럽게 전환
                }}
                // spaceBetween={50}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => {
                //   swiper.params.slidesPerGroup = 1;
                //   swiper.params.slidesPerView = 3;
                //   swiper.update();
                // }}
                autoplay={{
                  delay: 2100, // 2초마다 자동 전환
                  disableOnInteraction: true, // 사용자 상호작용 후에도 자동 재생 유지 = false
                }}
                pagination={{
                  clickable: isDetailPage === false ? false : true, // 페이지네이션 클릭 활성화
                }}
                // pagination={true}
                speed={isDetailPage === false ? 2000 : 700} // 전환 속도 (밀리초, 2초)
                loop={true} // 루프 활성화
                modules={[Pagination, Autoplay, EffectFade]} // 사용 모듈
                //한 페이지에 출력할 노드 수
                // slidesPerView={3}
                // modules={[Pagination]}
                // autoplay={
                //   // isDetailPage === true ?
                //   {
                //     delay: 2000,
                //     // disableOnInteraction: false,
                //   }
                //   // : undefined
                // }

                className="mySwiper"
                style={{
                  zIndex: 0,
                  height: "auto",
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  background: "transparent",
                }}
              >
                <Swiper>
                  {this.props.productImageList
                    // "REAL" 타입을 우선적으로 정렬
                    .sort((a: ProductDetailImg, b: ProductDetailImg) => {
                      return a.type === "REAL" ? -1 : 0;
                    })
                    .map((imgData: ProductDetailImg, index: number) => {
                      return (
                        <SwiperSlide
                          key={index}
                          style={{
                            zIndex: 0,
                            padding: 0,
                            margin: 0,
                            //   background: "transparent",
                            width: "100%",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {this.props.isDetailPage === undefined ||
                          this.props.isDetailPage === true
                            ? imgData.originalImgUrl !== "" && (
                                <img
                                  onError={(e) => {
                                    e.currentTarget.src = NoImg.src;
                                    e.currentTarget.onerror = null; // 무한 루프 방지
                                  }}
                                  src={imgData.originalImgUrl}
                                  style={{
                                    objectFit: "contain",

                                    zIndex: 0,
                                    width: "100%",
                                    height: "80vw",
                                  }}
                                />
                              )
                            : // <CardMedia
                              //   component="img"
                              //   // height="auto"
                              //   image={imgData.originalImgUrl}
                              //   style={{
                              //     height: "100vw",
                              //     // width:"100%",
                              //     // height:"100vw",
                              //     objectFit:"cover",
                              //   }}
                              //   alt={"img"}
                              // />
                              this.getImg(imgData)}
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </DynamicSwiper>
            </Link>
          </div>
        ) : (
          <Link
            href={this.props.link !== undefined ? this.props.link : "#"}
            style={{ textDecoration: "none" }}
          >
            {productImageList[0] && this.getImg(productImageList[0])}
          </Link>
        )}
      </>
    ) : (
      <div>데이터가 없습니다.</div>
    );
  }
}

export default withRouter(ProductDetailImageSlider);
